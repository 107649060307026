import MainMenu from "../components/MainMenu";
import Footer from "../components/Footer";
import {ThemeContext} from "../contexts/ThemeContext";
import {useEffect, useMemo, useState} from "react";
import useCRUD from "./../hooks/useCRUD";
import config from "../config.json";

export default function Screen(props) {

  const crud = useCRUD();

  const defaultTheme = useMemo(() => ({
    logoUrl: config.BRAND_DESIGN ? process.env.PUBLIC_URL + '/logo512.png' : '',
    company: config.BRAND_DESIGN ? 'Sellestar' : 'Ihre Firma'
  }), []);

  const [theme, setTheme] = useState({...defaultTheme});

  useEffect(() => {
    const updatedTheme = {...defaultTheme};
    crud.data.read({entity: 'configuration', id: 1})
      .then(person => {
        if (person.company) {
          updatedTheme.company = person.company;
        }
        if (person.logo_id) {
          crud.data.read({entity: 'file', id: parseInt(person.logo_id)})
            .then(file => {
              updatedTheme.logoUrl = file.url;
              setTheme(updatedTheme);
            })
            .catch(errors => console.error(errors));
        } else {
          setTheme(updatedTheme);
        }
      })
      .catch(errors => console.log(errors));
  }, [crud.data, defaultTheme]);

  return (
    <ThemeContext.Provider value={{theme, setTheme: (theme) => setTheme({...defaultTheme, ...theme})}}>
      <div className="Screen">
        <MainMenu/>
        {props.main}
        {config.BRAND_DESIGN && <Footer/>}
      </div>
    </ThemeContext.Provider>
  );

}
