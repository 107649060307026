import {NavLink, useNavigate} from "react-router-dom";
import Stage from "../../components/Stage";
import Title from "../../components/Title";
import TitleHeading from "../../components/TitleHeading";
import {useCallback, useContext, useEffect, useState} from "react";
import ErrorModal from "../../components/ErrorModal";
import {OfferContext} from "../../contexts/OfferContext";
import TextInput from "../../components/TextInput";
import {Form, Formik} from "formik";
import StageTitle from "../../components/StageTitle";
import DropzoneInput from "../../components/DropzoneInput";
import OfferFileOptionSelect from "../../components/OfferFileOptionSelect";
import * as Yup from "yup";
import ButtonBar from "../../components/ButtonBar";
import Button from "../../components/Button";
import Breadcrumb from "../../components/Breadcrumb";
import Select from "react-select";
import useCRUD from "../../hooks/useCRUD";
import FormErrors from "../../components/FormErrors";
import Modal from "../../components/Modal";
import useFiles from "../../hooks/useFiles";
import IconWithText from "../../components/IconWithText";
import {LoaderIcon} from "../../components/Icons";
import Switch from "../../components/Switch";

export default function TemplateOffer() {

  const navigate = useNavigate();
  const crud = useCRUD();
  const files = useFiles();
  const offerContext = useContext(OfferContext);
  const [errors, setErrors] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(true);

  const validationSchema = Yup.object({
    client_company_name: Yup.string()
      .test(
        'at-least-one-client-field', 'Bitte mindestens einen Firmennamen oder Kontaktnamen eingeben',
        function (value) {
          const { client_contact_name } = this.parent; // Zugriff auf das andere Feld
          return !!(value || client_contact_name); // Mindestens eines der Felder muss ausgefüllt sein
        }
      ),
    client_contact_name: Yup.string()
      .test(
        'at-least-one-client-field', 'Bitte mindestens einen Firmennamen oder Kontaktnamen eingeben',
        function (value) {
          const { client_company_name } = this.parent; // Zugriff auf das andere Feld
          return !!(value || client_company_name); // Mindestens eines der Felder muss ausgefüllt sein
        }
      ),
    title: Yup.string().required('Bitte den Titel des Angebotes eingeben'),
    new_offer_files: Yup.mixed()
      .required('Bitte mindestens ein Angebot hochladen')
      .test(
        'fileNameLength',
        'Der Dateiname ist zu lang! Maximale Zeichen: 120',
        (val) => (val?.name?.length ?? 0) <= 120
      )
      .test(
        'fileSize',
        'Die Datei ist zu groß! Maximale Größe: 100MB',
        (val) => (val?.size ?? 0) <= 100000000
      )
  });

  const [offerTemplates, setOfferTemplates] = useState(null);
  const [selectOptions, setSelectOptions] = useState([]);
  const [offerTemplateSelected, setOfferTemplateSelected] = useState({});

  useEffect(() => {
    setLoading(true);
    crud.data.bulk.read({
      entity: "offer",
      page_size: 1000,
      filter: {
        property: 'is_template',
        operator: 'equals',
        value: '1'
      }
    })
      .then(readOfferTemplates => {
        let options = [];
        readOfferTemplates.items.forEach(item => {
          options.push({value: item.id, label: item.title});
        });
        setSelectOptions(options);
        setOfferTemplates(readOfferTemplates.items);
      })
      .catch(errors => setErrors([errors]))
      .finally(() => setLoading(false));
  }, [crud.data]);

  const onsubmit = (values, formikBag) => {
    let fileError = false;
    if (values.new_offer_files) {
      values.new_offer_files.forEach(file => {
        if (file.option !== null && file.option !== 'e-mail' && file.accept_via_link.length === 0) {
          fileError = true;
          formikBag
            .setFieldError('new_offer_files', 'Für Käufe per digitaler Unterschrift muss ein Link angegeben werden');
        }
      });
    }

    if (!fileError) {
      let code = '';
      const pool = '0123456789abcdef';
      for (let i = 0; i < 32; i++) {
        code += pool.charAt(Math.floor(Math.random() * pool.length));
      }

      crud.data.create({entity: 'offer', item: {
        title : values.title,
        code : code,
        status: 'open',
        client_company_name : values.client_company_name,
        client_contact_name : values.client_contact_name,
        is_template : 0,
        source_template_id: offerTemplateSelected.id,
        contact_person_id: offerTemplateSelected.contact_person_id,
        header_image_id: offerTemplateSelected.header_image_id,
        testimonial_group_id: offerTemplateSelected.testimonial_group_id,
        faq_group_id: offerTemplateSelected.faq_group_id,
        usp_group_id: offerTemplateSelected.usp_group_id,
        cta_id: offerTemplateSelected.cta_id,
        translation_id: offerTemplateSelected.translation_id,
        letter: offerTemplateSelected.letter,
        offer_video_id: offerTemplateSelected.offer_video_id,
        introduction_video_id: offerTemplateSelected.introduction_video_id,
        opening: values.opening,
        offer_button_disabled: parseInt(offerTemplateSelected.offer_button_disabled) === 1 ? 1 : 0,
        use_customer_name: parseInt(values.use_customer_name ?? "0") === 1 ? 1 : 0
      }})
        .then(async (created_offer) => {
          await crud.data.update({entity: 'offer', id : created_offer.id, update: {number: "s-" + created_offer.id}});
          await crud.data.bulk.read({
            entity: "offer_has_file",
            page_size: 1000,
            filter: {
              group: 'or',
              components: [
                {property: 'offer_id', operator: 'equals', value: offerTemplateSelected.id},
              ]
            }
          }).then(async (offer_files) => {
            if (offer_files.total) {
              crud.data.bulk.create({
                entity: 'offer_has_file',
                items: offer_files.items.map(document => ({
                  offer_id: created_offer.id,
                  file_id: document.file_id ,
                  type: 'other',
                }))
              });
            }
          }).catch(errors => setErrors([errors]));

          values.new_offer_files.map(async hasFile => {
            let fileId = await files.upload(hasFile.file).then(result => result.id);
            await crud.data.create({
              entity: 'offer_has_file',
              item: {
                offer_id: created_offer.id,
                file_id: fileId,
                type: 'offer',
                accept_via_link: hasFile.option === 'e-mail' ? null : hasFile.option ?? null,
                accept_via_e_mail: hasFile.option === 'e-mail' ? '1' : null
              }
            });
          });

          // Copy picture galleries
          const picture_gallery_ids = await crud.data.bulk.read({
            entity: "offer_has_picture_gallery",
            page_size: 1000,
            filter: crud.filter.equals('offer_id', offerTemplateSelected.id)
          }).then(bulkReadResult => bulkReadResult.items.map(item => item.picture_gallery_id));
          if (picture_gallery_ids.length > 0) {
            await crud.data.bulk.create({
              entity: 'offer_has_picture_gallery',
              items: picture_gallery_ids.map(picture_gallery_id => ({
                offer_id: created_offer.id,
                picture_gallery_id: picture_gallery_id
              }))
            });
          }

          await crud.data.bulk.read({
            entity: "offer_has_logo_gallery",
            page_size: 1000,
            filter: {
              group: 'or',
              components: [
                {property: 'offer_id', operator: 'equals', value: offerTemplateSelected.id},
              ]
            }
          }).then(async (logo_galleries) => {
            if (logo_galleries.total) {
              await crud.data.bulk.create({
                entity: 'offer_has_logo_gallery',
                items: logo_galleries.items.map(item => ({
                  offer_id: created_offer.id,
                  logo_gallery_id: item.logo_gallery_id
                }))
              });
            }
          })
            .catch(errors => setErrors([errors]));

          await crud.data.bulk.read({
            entity: "offer_has_team_member",
            page_size: 1000,
            filter: {
              group: 'or',
              components: [
                {property: 'offer_id', operator: 'equals', value: offerTemplateSelected.id},
              ]
            }
          }).then(async (team_members) => {
            if (team_members.total) {
              await crud.data.bulk.create({
                entity: 'offer_has_team_member',
                items: team_members.items.map(item => ({
                  offer_id: created_offer.id,
                  team_member_id: item.team_member_id
                }))
              });
            }
          })
            .catch(errors => setErrors([errors]));
        })
        .catch(errors => setErrors([errors]))
        .finally(() => {
          offerContext.resetOffer();
          setShowSuccess(true);
        });
    }
  };

  const changeOfferTemplate = useCallback((newTemplate) => {
    if (newTemplate.value !== offerTemplateSelected.id) {
      offerTemplates.forEach(item => {
        if (newTemplate.value === item.id) {
          setOfferTemplateSelected(item);
        }
      });
    }
  }, [offerTemplateSelected, offerTemplates]);

  useEffect(() => {
    if (!loading && offerContext.offer.offerTemplate) {
      changeOfferTemplate(offerContext.offer.offerTemplate);
    }
  }, [changeOfferTemplate, offerContext.offer.offerTemplate, loading]);

  return (
    <main>
      <Title>
        <TitleHeading>Angebote</TitleHeading>
        <Breadcrumb>
          <NavLink to="/angebote">Angebote</NavLink>
          Angebot mit Vorlage
        </Breadcrumb>
      </Title>
      <Stage>
        {loading && <IconWithText><LoaderIcon/> Laden…</IconWithText>}
        {!loading &&
        <Formik
          initialValues={{
            client_company_name: '',
            client_contact_name: '',
            title: offerTemplateSelected.title ?? '',
            new_offer_files: '',
            opening: '',
            use_customer_name: offerContext.offer.use_customer_name ?? 0
          }}
          onSubmit={onsubmit}
          validationSchema={validationSchema}>
          {({touched, errors, setFieldValue, values}) => (
            <Form>
              <Stage>
                <StageTitle>Wähle deine Vorlage</StageTitle>
                <Select
                  options={selectOptions}
                  inputValue={''}
                  onChange={e => changeOfferTemplate(e)}
                  onInputChange={() => {}}
                  onMenuClose={() => {}}
                  onMenuOpen={() => {}}
                  placeholder={offerTemplateSelected.title ?? <div>Auswählen...</div>}
                />
                <StageTitle className="mt-1-75">Angebote</StageTitle>
                <DropzoneInput
                  name="new_offer_files" multiple label="Angebot(e)*" className="mt-1-75"
                  displayFiles={(offerFile, onRemove) => <OfferFileOptionSelect
                    offerFile={offerFile} onRemove={onRemove} name="new_offer_files"
                  />}
                />
                {
                  offerContext.offer.offer_files?.map(
                    (offerHasFile, index) => values.delete_offer_files.includes(offerHasFile.id) ? null : (
                      <div key={index} className="mt-1">
                        <OfferFileOptionSelect
                          offerFile={offerHasFile} name="offer_files"
                          label={
                            <a href={offerHasFile.file.url} target="_blank" rel="noreferrer">
                              {offerHasFile.file.filename}
                            </a>
                          }
                          onRemove={() => {
                            setFieldValue('delete_offer_files', [...values.delete_offer_files, offerHasFile.id]);
                          }}
                        />
                      </div>
                    )
                  )
                }
                <StageTitle className="mt-1-75">Dein Kunde</StageTitle>
                <div>
                  <div style={{display: 'flex', gap: '1rem', justifyContent: 'space-between', marginBottom: '5px'}}>
                    <label
                      htmlFor="client_company_name"
                      className={touched.client_company_name && errors.client_company_name ? 'has-error' : ''}
                    >
                      Firmenname deines Kunden
                    </label>
                    <div style={{display: 'flex', gap: '.5rem'}}>
                      <Switch name="use_customer_name" onClick={() => {
                        setFieldValue(
                          'use_customer_name',
                          values.use_customer_name === 0 ? 1 : 0
                        );
                      }} active={values.use_customer_name === 1}/>
                      <label onClick={() => {
                        setFieldValue(
                          'use_customer_name',
                          values.use_customer_name === 0 ? 1 : 0
                        );
                      }} htmlFor="use_customer_name">Anzeigen</label>
                    </div>
                  </div>
                  <TextInput
                    name="client_company_name"
                    placeholder="Bsp. vyn Marketing GmbH"
                  />
                </div>
                <TextInput name="client_contact_name" label="Name deines Kunden" placeholder="Bsp. Sascha Weinrich"
                  className={"mt-1"}/>
                <TextInput
                  name="opening" label="Persönliche Anrede" placeholder="Bsp. Sehr geehrter Herr Schmidt"
                  className={"mt-1"}
                />
                <TextInput name="title" label="Titel des Angebots*" placeholder="Bsp. Werden Sie unser starker Partner"
                  className={"mt-1"}/>
              </Stage>
              <ButtonBar>
                <Button
                  text="Speichern" type="submit"
                />
              </ButtonBar>
              <FormErrors touched={touched} errors={errors}/>
              <Modal title="Das Angebot wurde erfolgreich erstellt" show={showSuccess}
                onDismiss={() => navigate('/angebote')}>
                <ButtonBar>
                  <Button type="button" text="Ok" onClick={() => navigate('/angebote')}/>
                </ButtonBar>
              </Modal>
            </Form>
          )}
        </Formik>
        }
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
      </Stage>
    </main>
  );
}
