import config from "../config.json";
import {useContext, useMemo} from "react";
import {AuthContext} from "../contexts/AuthContext";
import {TenantContext} from "../contexts/TenantContext";

export default function useBL(authToken) {

  const authContext = useContext(AuthContext);
  const tenantContext = useContext(TenantContext);

  return useMemo(() => {

    const call = (remoteFunction, remoteArguments = {}) => {
      let url = config.BL_URL + '/' + remoteFunction.replaceAll('/', '.');
      return new Promise((resolve, reject) => {
        let body = {
          function: remoteFunction,
          parameters: remoteArguments,
          tenant: tenantContext.tenant
        };
        if (config.USE_AUTH && (authToken || authContext.authToken)) {
          body.auth = authToken || authContext.authToken;
        }
        fetch(
          url,
          {
            method: 'POST',
            body: JSON.stringify(body)
          })
          .then(response => {
            if (response.status !== 200) {
              reject([{message: 'Interner Fehler in der Business Logik.'}]);
            } else {
              response.json()
                .then(result => {
                  if ('errors' in result && result.errors.length > 0) {
                    reject(result.errors);
                  } else {
                    resolve(result.result);
                  }
                })
                .catch(() => {
                  return [{message: 'Fehler beim Verarbeiten der Antwort aus der Business Logik.'}];
                });
            }
          })
          .catch(error => {
            console.error(error);
            reject([{
              message: 'Die Verbindung zur Business Logik kann nicht hergestellt werden.'
            }]);
          });
      });
    };

    return {
      login: ({email, password}) => call('login', {email, password}),
      change_password: ({old_password, new_password}) => call('change_password', {old_password, new_password}),
      recover_password: ({email, url}) => call('recover_password', {email, url}),
      reset_password: ({token, password}) => call('reset_password', {token, password}),
      validate_password_reset_token: ({token}) => call('validate_password_reset_token', {token}),
      ensure_password_complexity: ({password}) => call('ensure_password_complexity', {password}),
      ensure_available_email: ({email}) => call('ensure_available_email', {email}),
      event_statistics: ({offer_id, type}) => call('event_statistics', {offer_id, type}),
      offer_statistics: ({year}) => call('offer_statistics', {year}),
      faq_statistics: ({offer_id}) => call('faq_statistics', {offer_id}),
      download_statistics: ({offer_id}) => call('download_statistics', {offer_id}),
      count_devices: ({offer_id}) => call('count_devices', {offer_id}),
      count_customers: ({status}) => call('count_customers', {status}),
      create_contact_person: ({item, url}) => call('create_contact_person', {item, url}),
      update_contact_person: ({item, id}) => call('update_contact_person', {item, id}),
      any_offers_created: () => call('any_offers_created'),
      create_tenant: ({tenant, email, password, subscription_plan, options}) => call(
        'create_tenant', {tenant, email, password, subscription_plan, options}
      ),
      redeem_token_reference: ({token_reference}) => call('redeem_token_reference', {token_reference}),
      process_video: ({id}) => call('process_video', {id}),
      process_audio: ({id}) => call('process_audio', {id}),
      renew_auth_token: () => call('renew_auth_token'),
      tenant_from_hostname: ({hostname}) => call('tenant_from_hostname', {hostname}),
      generate_video_upload_token: () => call('generate_video_upload_token')
    };

  }, [authContext.authToken, authToken, tenantContext.tenant]);

}
