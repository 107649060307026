import Title from '../../components/Title';
import Stage from '../../components/Stage';
import ButtonBar from "../../components/ButtonBar";
import TitleHeading from "../../components/TitleHeading";
import StageTitle from "../../components/StageTitle";
import {Form, Formik} from "formik";
import Button from "../../components/Button";
import {ChevronLeftIcon, LoaderIcon} from "../../components/Icons";
import ButtonLink from "../../components/ButtonLink";
import {useEffect, useRef, useState} from "react";
import useCRUD from "../../hooks/useCRUD";
import useTemplates from "../../hooks/useTemplates";
import {useParams} from "react-router-dom";
import ErrorModal from "../../components/ErrorModal";
import IconWithText from "../../components/IconWithText";
import ButtonWithTextInput from "../../components/ButtonWithTextInput";
import Accordion from "../../components/Accordion";
import RadioButton from "../../components/RadioButton";
import DateDisplay from "../../components/DateDisplay";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import de from 'date-fns/locale/de';
import Row from "../../components/Row";

export default function Link() {

  const crud = useCRUD();
  const params = useParams();
  const templates = useTemplates();
  const [link, setLink] = useState('');
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [useSlideOption, setUseSlideOption] = useState(false);
  const [slideOptionAvailable, setSlideOptionAvailable] = useState(false);
  const [usePassword, setUsePassword] = useState(false);
  const [offer, setOffer] = useState(null);
  const [design, setDesign] = useState();
  const [useExpiration, setUseExpiration] = useState(false);
  const [useCustomDate, setUseCustomDate] = useState(false);
  const [reloadKey, setReloadKey] = useState(0);
  const formikRef = useRef(null);
  registerLocale('de', de);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      crud.data.read({entity: 'offer', id: parseInt(params.id)}),
      crud.data.read({entity: 'design', id: 1})
    ])
      .then(([offerResult, design]) => {
        setOffer(offerResult);
        setDesign(design);
        setUsePassword(offerResult.use_password > 0 && offerResult.password);
        setUseExpiration(offerResult.use_expiration_date > 0 && offerResult.expiration_date);
      })
      .catch(errors => setErrors(errors))
      .finally(() => setLoading(false));
  }, [crud.data, params.id]);

  useEffect(() => {
    if (design && offer) {
      let url = new URL(window.location.href);
      let templateCode;
      templates.forEach(template => {
        if (template.id === parseInt(design.template)) {
          templateCode = template.code;
          setSlideOptionAvailable(template.slideMode);
        }
      });
      const newLink = `${url.origin}/${templateCode}/?code=${offer.code}`  + (useSlideOption ? '&slide=hero' : '');
      if (formikRef.current) {
        formikRef.current.setFieldValue('link', newLink);
      }
      setLink(newLink);
    }
  }, [templates, offer, useSlideOption, design]);

  const enablePassword = () => {
    const password = offer.password ?? generatePassword();
    crud.data.update({entity: 'offer', id: parseInt(params.id), update: {use_password: 1, password}})
      .then(() => {
        setOffer({...offer, use_password: '1', password});
        formikRef.current.setFieldValue('password', password);
        setUsePassword(true);
      })
      .catch(errors => setErrors(errors));
  };

  const disablePassword = () => {
    crud.data.update({entity: 'offer', id: parseInt(params.id), update: {use_password: 0}})
      .then(() => {
        setOffer({...offer, use_password: '0'});
        setUsePassword(false);
      })
      .catch(errors => setErrors(errors));
  };

  const changeExpirationDateUsage = (setFieldValue, days, toggleExpiration = false) => {
    let {id, revision, updated_at, created_at, deleted_at ,...cleanOffer} = offer;

    if (days >= 0 || !offer.expiration_date) {
      let date = new Date();
      if (offer.expiration_date) {
        date.setDate(date.getDate() + parseInt(days));
      }
      let dateString =
        date.getFullYear()
        + '-' + String(date.getMonth() + 1).padStart(2, '0')
        + '-' + String(date.getDate()).padStart(2, '0');
      cleanOffer = {...cleanOffer, expiration_date: dateString};
    }

    if (toggleExpiration) {
      cleanOffer = {...cleanOffer, use_expiration_date: !useExpiration ? 1 : 0};
      setUseExpiration(!useExpiration);
    }

    if (toggleExpiration || days >= 0 || !offer.expiration_date) {
      crud.data.update({entity: 'offer', id: parseInt(params.id), update: cleanOffer})
        .then(() => {
          setOffer(cleanOffer);
          setFieldValue('expiration_date', cleanOffer.expiration_date);
        })
        .catch(errors => setErrors(errors));
    }
  };

  const generatePassword = () => {
    let length = 8,
      charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!§$%&/?+-",
      newPassword = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      newPassword += charset.charAt(Math.floor(Math.random() * n));
    }
    return newPassword;
  };

  return (
    <main>
      <Title>
        <TitleHeading>Angebote</TitleHeading>
      </Title>
      <Stage>
        <StageTitle>Link zur Angebotswebsite</StageTitle>
        <p>Den Link sendest du an deinen Kunden, damit dieser Zugriff auf die Angebotswebsite hat.</p>
        {loading && <IconWithText><LoaderIcon/> Laden…</IconWithText>}
        {!loading &&
          <Formik
            innerRef={formikRef} onSubmit={() => {}} initialValues={{
              link: link, password: offer.password ?? '', expiration_date: offer.expiration_date, days: -1
            }}
          >
            {({values, setFieldValue}) => (
              <Form className="mt-1-75">
                <ButtonWithTextInput label="Link" name="link" readOnly>
                  <Button text="Link kopieren" onClick={() => {
                    navigator.clipboard.writeText(values.link)
                      .catch(() => alert('Fehler: Text konnte nicht in die Zwischenablage kopiert werden'));
                  }}/>
                </ButtonWithTextInput>
                {
                  slideOptionAvailable &&
                  <Accordion
                    title="Folien-Option?" className="mt-1" active={useSlideOption}
                    onToggle={() => setUseSlideOption(!useSlideOption)}
                  />
                }
                <Accordion
                  title="Passwortgeschützt?" className="mt-1" active={usePassword}
                  onToggle={usePassword ? disablePassword : enablePassword}
                >
                  <ButtonWithTextInput label="Passwort" name="password" readOnly>
                    <Button text="Passwort kopieren" onClick={() => {
                      navigator.clipboard.writeText(values.password)
                        .catch(() => alert('Fehler: Text konnte nicht in die Zwischenablage kopiert werden'));
                    }}/>
                    <Button text="Link und Passwort kopieren" onClick={() => {
                      navigator.clipboard.writeText(`${values.link} \n${values.password}`)
                        .catch(() => alert('Fehler: Text konnte nicht in die Zwischenablage kopiert werden'));
                    }}/>
                  </ButtonWithTextInput>
                </Accordion>
                <Accordion
                  title="Beschränkte Gültigkeit?" className="mt-1" active={useExpiration} reload={reloadKey}
                  onToggle={() => changeExpirationDateUsage(setFieldValue, values.days, true)}>
                  <label>Wie lange soll der Link gültig sein?</label>
                  <ButtonBar align="left" className="mt-1">
                    <RadioButton name="days" text="7 Tage" value="7"
                      onClick={() => {
                        changeExpirationDateUsage(setFieldValue, 7);
                        setUseCustomDate(false);
                      }}/>
                    <RadioButton name="days" text="14 Tage" value="14"
                      onClick={() => {
                        changeExpirationDateUsage(setFieldValue, 14);
                        setUseCustomDate(false);
                      }}/>
                    <RadioButton name="days" text="30 Tage" value="30"
                      onClick={() => {
                        changeExpirationDateUsage(setFieldValue, 30);
                        setUseCustomDate(false);
                      }}/>
                    <RadioButton name="days" text="Benutzerdefiniert" value="-1"
                      onClick={() => {
                        setUseCustomDate(true);
                        setReloadKey(reloadKey + 1);
                      }}/>
                    <div className="pos-absolut">
                    </div>
                  </ButtonBar>
                  <Row
                    className="mt-1-75" lgWidth="75%" style={{'flexWrap': 'wrap-reverse', 'alignItems': 'flex-end'}}
                  >
                    <DateDisplay label="Ablaufdatum" date={new Date(values.expiration_date)}/>
                    {
                      useCustomDate &&
                      <div onClick={() => setReloadKey(reloadKey + 1)}>
                        <DatePicker inline selected={new Date(offer.expiration_date)} minDate={new Date()}
                          locale="de" disabledKeyboardNavigation
                          onChange={date => {
                            setReloadKey(reloadKey + 1);
                            let days = (date - new Date().setHours(0, 0, 0, 0)) / 1000 / 60 / 60 / 24;
                            changeExpirationDateUsage(setFieldValue, days);
                          }}
                        />
                      </div>
                    }
                  </Row>
                </Accordion>
              </Form>
            )}
          </Formik>
        }
        <ButtonBar align="left">
          <ButtonLink to="/angebote" color="gray" icon={<ChevronLeftIcon/>} iconPosition="left" text="Zurück"/>
        </ButtonBar>
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
      </Stage>
    </main>
  );
}
