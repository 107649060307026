import PublicModal from "../../components/PublicModal";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import TextInput from "../../components/TextInput";
import {LoaderIcon} from "../../components/Icons";
import Button from "../../components/Button";
import FormErrors from "../../components/FormErrors";
import {useState} from "react";
import ButtonBar from "../../components/ButtonBar";
import {useNavigate} from "react-router-dom";
import Row from "../../components/Row";
import useBL from "../../hooks/useBL";
import config from "../../config.json";

export default function Company({registrationData, setRegistrationData}) {

  const navigate = useNavigate();
  const bl = useBL();

  const [remoteErrors, setRemoteErrors] = useState([]);

  const validationSchema = Yup.object({
    company: Yup.string().required('Bitte den Namen des Unternehmens eingeben')
  });

  const transform = input => input.toLowerCase()
    .replace(/ /g, '-').replace(/ä/g, 'ae').replace(/ö/g, 'oe').replace(/ü/g, 'ue').replace(/ß/g, 'ss')
    .replace(/[^a-z0-9_-]/g, '');

  const submit = (values, {setSubmitting}) => {
    bl.create_tenant({
      ...registrationData,
      tenant: transform(values.company),
      options: {show_branding: config.BRAND_DESIGN}
    })
      .then(tokenReference => {
        setSubmitting(false);
        setRegistrationData({...registrationData, tenant: transform(values.company), tokenReference});
        navigate('/registrieren/fertig');
      })
      .catch(remoteErrors => setRemoteErrors(remoteErrors))
      .finally(() => setSubmitting(false));
  };

  let tenantUrl = tenant => {
    let parts = window.location.hostname.split('.');
    parts.splice(0, 1, tenant);
    return 'https://' + parts.join('.');
  };

  return (
    <PublicModal>
      <h1>Erstelle dein Unternehmen</h1>
      <p className="info-text">
        Lass uns noch schnell den Link festlegen, unter dem deine Angebote aufrufbar sein sollen.
      </p>
      <Formik
        initialValues={{company: 'beispiel'}}
        validationSchema={validationSchema}
        onSubmit={submit} validate={() => setRemoteErrors([])}
      >
        {({isSubmitting, touched, errors, values, setFieldValue}) => (
          <Form>
            <TextInput name="company" placeholder="Name deiner Firma" onFocus={value => {
              if (value.target.value === 'beispiel') {
                setFieldValue('company', '');
              }
            }}/>
            <div>{tenantUrl(transform(values.company))}</div>
            <Row>
              <ButtonBar>
                <Button color="gray" text="Zurück" onClick={() => navigate(-1)} disabled={isSubmitting}/>
                <Button
                  color="gray" text="Fertig" type="submit" icon={isSubmitting ? <LoaderIcon/> : null}
                  disabled={isSubmitting}
                />
              </ButtonBar>
            </Row>
            <FormErrors touched={touched} errors={errors} remoteErrors={remoteErrors}/>
          </Form>
        )}
      </Formik>
    </PublicModal>
  );

}
