import './FormErrors.scss';

export default function FormErrors({ errors, touched, remoteErrors = [] }) {

  // Alle Fehlermeldungen (nur die Bildschirmtexte) hier drin sammeln
  let messages = [];

  // Füge Formik-Fehler hinzu (nur für berührte Felder)
  Object.entries(errors).forEach(([key, message]) => {
    if (!touched || touched[key]) {
      messages.push(message);
    }
  });

  // Füge Remote-Fehler hinzu
  remoteErrors.forEach(remoteError => messages.push(remoteError.message));

  // Entferne doppelte Fehlermeldungen
  const uniqueMessages = [...new Set(messages)];

  // Rendere die Fehlermeldungen, falls vorhanden
  return uniqueMessages.length > 0 ? (
    <div className="FormErrors">
      <p>
        {uniqueMessages.length === 1 && 'Bei der Verarbeitung des Formulars ist folgender Fehler aufgetreten:'}
        {uniqueMessages.length !== 1 && 'Bei der Verarbeitung des Formulars sind folgende Fehler aufgetreten:'}
      </p>
      <ul>
        {uniqueMessages.map((message, index) => <li key={index}>{message}</li>)}
      </ul>
    </div>
  ) : null;

}
