import TrackStation from "../../../components/TrackStation";
import Track from "../../../components/Track";
import {Form, Formik} from "formik";
import {ChevronLeftIcon, ChevronRightIcon, LoaderIcon} from "../../../components/Icons";
import Button from "../../../components/Button";
import ButtonBar from "../../../components/ButtonBar";
import ButtonLink from "../../../components/ButtonLink";
import Accordion from "../../../components/Accordion";
import SelectInput from "../../../components/SelectInput";
import Row from "../../../components/Row";
import {useContext, useEffect, useState} from "react";
import {OfferContext} from "../../../contexts/OfferContext";
import useCRUD from "../../../hooks/useCRUD";
import Modal from "../../../components/Modal";
import {NavLink, useNavigate} from "react-router-dom";
import ErrorModal from "../../../components/ErrorModal";
import useFiles from "../../../hooks/useFiles";
import {TemplateContext} from "../../../contexts/TemplateContext";

export default function Elements() {

  const crud = useCRUD();
  const files = useFiles();
  const navigate = useNavigate();
  const offerContext = useContext(OfferContext);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [useContactPerson, setUseContactPerson] = useState(offerContext.offer.use_contact_person);
  const [useTeamMembers, setUseTeamMembers] = useState(offerContext.offer.use_team_members);
  const [useHeaderImage, setUseHeaderImage] = useState(offerContext.offer.use_header_image);
  const [useUsp, setUseUsp] = useState(offerContext.offer.use_usp);
  const [useFaq, setUseFaq] = useState(offerContext.offer.use_faq);
  const [usePictureGalleries, setUsePictureGalleries] = useState(offerContext.offer.use_picture_galleries);
  const [useLogoGalleries, setUseLogoGalleries] = useState(offerContext.offer.use_logo_galleries);
  const [useDocuments, setUseDocuments] = useState(offerContext.offer.use_documents);
  const [useCta, setUseCta] = useState(offerContext.offer.use_cta);
  const [useTranslation, setUseTranslation] = useState(offerContext.offer.translation_id);
  const [useTestimonials, setUseTestimonials] = useState(offerContext.offer.use_testimonials);
  const templateContext = useContext(TemplateContext);

  const arraysAreIdentical =
    (a, b) => a.length === b.length && a.every((element, index) => element?.toString() === b[index]?.toString());

  const onSubmit = (values, {setSubmitting}) => {

    const offer = {...offerContext.offer};

    let emptyRequiredFieldsErrors = [];

    if (!offer.client_company_name && !offer.client_contact_name) {
      emptyRequiredFieldsErrors = [
        ...emptyRequiredFieldsErrors, {message: "Der Firmenname oder der Name deines Kunden fehlt"}
      ];
    }
    if (!offer.number) {
      emptyRequiredFieldsErrors = [...emptyRequiredFieldsErrors, {message: "Die Angebotsnummer fehlt"}];
    }
    if (offer.net_price && !(/^\d+(,\d+)?$/).test(offer.net_price)) {
      emptyRequiredFieldsErrors = [...emptyRequiredFieldsErrors, {message: "Die Angebotssumme (netto) ist falsch"}];
    }
    let fileError = false;

    offerContext.offer.offer_files.filter(item => !offerContext.offer.delete_offer_files.includes(item.id))
      .every(file => {
        if (file.accept_via_e_mail !== '1' && file.option?.length === 0) {
          fileError = true;
          return false;
        } else {
          return true;
        }
      });

    offerContext.offer.new_offer_files.every(file => {
      if (file.accept_via_e_mail !== '1' && file.option?.length === 0) {
        fileError = true;
        return false;
      } else {
        return true;
      }
    });

    if (fileError) {
      emptyRequiredFieldsErrors = [
        ...emptyRequiredFieldsErrors,
        {message: "Für Käufe per digitaler Unterschrift muss ein Link angegeben werden"}
      ];
    }
    if (emptyRequiredFieldsErrors.length > 0) {
      setErrors(emptyRequiredFieldsErrors);
      return;
    }

    delete offer.offer_file_error;
    delete offer.id;
    if (!offer.use_contact_person) {
      offer.contact_person_id = null;
    }
    delete offer.use_contact_person;
    if (!offer.use_header_image) {
      offer.header_image_id = null;
    }
    delete offer.use_header_image;
    if (!offer.use_usp) {
      offer.usp_group_id = null;
    }
    delete offer.use_usp;
    if (!offer.use_faq) {
      offer.faq_group_id = null;
    }
    delete offer.use_faq;
    if (!offer.use_cta) {
      offer.cta_id = null;
    }
    delete offer.use_cta;
    if (!offer.use_testimonials) {
      offer.testimonial_group_id = null;
    }
    delete offer.use_testimonials;
    offer.picture_gallery_id = null;
    delete offer.picture_galleries;
    delete offer.use_picture_galleries;
    delete offer.logo_galleries;
    delete offer.use_logo_galleries;
    delete offer.new_offer_files;
    delete offer.offer_files;
    delete offer.delete_offer_files;
    delete offer.use_documents;
    delete offer.documents;
    delete offer.offerTemplate;
    delete offer.use_team_members;
    delete offer.team_members;
    offer.net_price = offer.net_price ? (parseFloat(offer.net_price.replace(',', '.')) * 100).toFixed(0) : null;

    let promise;
    if (offerContext.offer.id) {
      promise = crud.data.update({entity: 'offer', id: offerContext.offer.id, update: offer});
    } else {
      offer.code = '';
      const pool = '0123456789abcdef';
      for (let i = 0; i < 32; i++) {
        offer.code += pool.charAt(Math.floor(Math.random() * pool.length));
      }
      promise = crud.data.create({entity: 'offer', item: offer});
    }
    promise
      .then(async offer => {

        offerContext.offer.new_offer_files.map(async hasFile => {
          let fileId = await files.upload(hasFile.file).then(result => result.id);
          await crud.data.create({
            entity: 'offer_has_file',
            item: {
              offer_id: offer.id,
              file_id: fileId,
              type: 'offer',
              accept_via_link: hasFile.option === 'e-mail' ? null : hasFile.option ?? null,
              accept_via_e_mail: hasFile.option === 'e-mail' ? '1' : null
            }
          });
        });

        if (offerContext.offer.delete_offer_files.length > 0) {
          await crud.data.bulk.delete({
            entity: 'offer_has_file',
            filter: {
              group: 'or',
              components: offerContext.offer.delete_offer_files.map(id => ({group: 'and', components: [
                {property: 'offer_id', operator: 'equals', value: offer.id},
                {property: 'id', operator: 'equals', value: id}
              ]}))
            }
          });
        }

        let existingPictureGalleryIds = [];
        if (offerContext.offer.id) {
          existingPictureGalleryIds = await crud.data.bulk.read({
            entity: 'offer_has_picture_gallery',
            page_size: 1000,
            filter: crud.filter.equals('offer_id', offerContext.offer.id)
          }).then(bulkReadResult => bulkReadResult.items.map(item => item.id));
        }
        let targetPictureGalleryIds = usePictureGalleries ? offerContext.offer.picture_galleries : [];
        if (!arraysAreIdentical(existingPictureGalleryIds, targetPictureGalleryIds)) {
          if (existingPictureGalleryIds.length > 0) {
            await crud.data.bulk.delete({
              entity: 'offer_has_picture_gallery',
              filter: crud.filter.equals('offer_id', offer.id)
            });
          }
          if (targetPictureGalleryIds.length > 0) {
            await crud.data.bulk.create({
              entity: 'offer_has_picture_gallery',
              items: targetPictureGalleryIds.map(id => ({offer_id: offer.id, picture_gallery_id: id}))
            });
          }
        }

        offerContext.offer.offer_files.filter(item => !offerContext.offer.delete_offer_files.includes(item.id))
          .map(async hasFile => {
            await crud.data.update({
              entity: 'offer_has_file',
              id: hasFile.id,
              update: {
                offer_id: offer.id,
                file_id: hasFile.file_id,
                type: 'offer',
                accept_via_link: hasFile.option === 'e-mail' ? null : hasFile.option ?? null,
                accept_via_e_mail: hasFile.option === 'e-mail' ? '1' : null
              }
            });
          });

        const [actualTeamMemberIds, targetTeamMemberIds] = await Promise.all([
          crud.data.bulk.read({
            entity: 'offer_has_team_member',
            page_size: 1000,
            filter: crud.filter.equals('offer_id', offer.id)
          }).then(bulkReadResult =>
            bulkReadResult.items.map(offerHasTeamMember => offerHasTeamMember.team_member_id)
          ),
          offerContext.offer.team_members.length > 0 && offerContext.offer.use_team_members ?
            crud.data.bulk.read({
              entity: 'team_member',
              page_size: 1000,
              filter: {group: 'or', components: offerContext.offer.team_members.map(
                id => ({property: 'id', operator: 'equals', value: id})
              )}
            }).then(bulkReadResult => bulkReadResult.items.map(TeamMember => TeamMember.id)) :
            new Promise(resolve => resolve([]))
        ]);

        if (offerContext.offer.team_members.length > 0) {
          const operations = [];
          const deleteIds = actualTeamMemberIds.filter(id => !targetTeamMemberIds.includes(id));
          const addIds = targetTeamMemberIds.filter(id => !actualTeamMemberIds.includes(id));

          if (deleteIds.length > 0) {
            operations.push(crud.data.bulk.delete({entity: 'offer_has_team_member', filter: {
              group: 'or',
              components: actualTeamMemberIds.filter(id => !targetTeamMemberIds.includes(id)).map(id => ({
                group: 'and', components: [
                  {property: 'offer_id', operator: 'equals', value: offer.id},
                  {property: 'team_member_id', operator: 'equals', value: id}
                ]
              }))
            }}));
          }

          if (addIds.length > 0) {
            operations.push(crud.data.bulk.create({
              entity: 'offer_has_team_member',
              items: targetTeamMemberIds.filter(id => !actualTeamMemberIds.includes(id)).map(
                id => ({offer_id: offer.id, team_member_id: id}))
            }));
          }

          await Promise.all(operations);

        } else if (actualTeamMemberIds.filter(id => !targetTeamMemberIds.includes(id)).length > 0) {
          await crud.data.bulk.delete({
            entity: 'offer_has_team_member', filter: crud.filter.equals('offer_id', offer.id)
          });
        }

        const [actualLogoGalleryIds, targetLogoGalleryIds] = await Promise.all([
          crud.data.bulk.read({
            entity: 'offer_has_logo_gallery',
            page_size: 1000,
            filter: crud.filter.equals('offer_id', offer.id)
          }).then(bulkReadResult =>
            bulkReadResult.items.map(offerHasLogoGallery => offerHasLogoGallery.logo_gallery_id)
          ),
          offerContext.offer.logo_galleries.length > 0 && offerContext.offer.use_logo_galleries ?
            crud.data.bulk.read({
              entity: 'logo_gallery',
              page_size: 1000,
              filter: {group: 'or', components: offerContext.offer.logo_galleries.map(
                id => ({property: 'id', operator: 'equals', value: id})
              )}
            }).then(bulkReadResult => bulkReadResult.items.map(logoGallery => logoGallery.id)) :
            new Promise(resolve => resolve([]))
        ]);

        if (offerContext.offer.logo_galleries.length > 0) {
          const operations = [];
          const deleteIds = actualLogoGalleryIds.filter(id => !targetLogoGalleryIds.includes(id));
          const addIds = targetLogoGalleryIds.filter(id => !actualLogoGalleryIds.includes(id));

          if (deleteIds.length > 0) {
            operations.push(crud.data.bulk.delete({entity: 'offer_has_logo_gallery', filter: {
              group: 'or',
              components: actualLogoGalleryIds.filter(id => !targetLogoGalleryIds.includes(id)).map(id => ({
                group: 'and', components: [
                  {property: 'offer_id', operator: 'equals', value: offer.id},
                  {property: 'logo_gallery_id', operator: 'equals', value: id}
                ]
              }))
            }}));
          }

          if (addIds.length > 0) {
            operations.push(crud.data.bulk.create({
              entity: 'offer_has_logo_gallery',
              items: targetLogoGalleryIds.filter(id => !actualLogoGalleryIds.includes(id)).map(
                id => ({offer_id: offer.id, logo_gallery_id: id}))
            }));
          }

          await Promise.all(operations);

        } else if (actualLogoGalleryIds.filter(id => !targetLogoGalleryIds.includes(id)).length > 0) {
          await crud.data.bulk.delete({
            entity: 'offer_has_logo_gallery', filter: crud.filter.equals('offer_id', offer.id)
          });
        }

        if (offerContext.offer.documents.length > 0) {

          const [actualFileIds, targetFileIds] = await Promise.all([
            crud.data.bulk.read({
              entity: 'offer_has_file',
              page_size: 1000,
              filter: {group: 'and', components: [
                {property: 'offer_id', operator: 'equals', value: offer.id},
                {property: 'type', operator: 'equals', value: 'other'}
              ]}
            }).then(bulkReadResult => bulkReadResult.items.map(offerHasFile => offerHasFile.file_id)),
            offerContext.offer.documents.length > 0 && offerContext.offer.use_documents ?
              crud.data.bulk.read({
                entity: 'document',
                page_size: 1000,
                filter: {group: 'or', components: offerContext.offer.documents.map(
                  id => ({property: 'id', operator: 'equals', value: id})
                )}
              }).then(bulkReadResult => bulkReadResult.items.map(document => document.file_id)) :
              new Promise(resolve => resolve([]))
          ]);

          const operations = [];
          const deleteIds = actualFileIds.filter(id => !targetFileIds.includes(id));
          const addIds = targetFileIds.filter(id => !actualFileIds.includes(id));

          if (deleteIds.length > 0) {
            operations.push(crud.data.bulk.delete({entity: 'offer_has_file', filter: {
              group: 'or',
              components: actualFileIds.filter(id => !targetFileIds.includes(id)).map(id => ({
                group: 'and', components: [
                  {property: 'offer_id', operator: 'equals', value: offer.id},
                  {property: 'file_id', operator: 'equals', value: id},
                  {property: 'type', operator: 'equals', value: 'other'},
                ]
              }))
            }}));
          }

          if (addIds.length > 0) {
            operations.push(crud.data.bulk.create({
              entity: 'offer_has_file',
              items: targetFileIds.filter(id => !actualFileIds.includes(id)).map(
                id => ({offer_id: offer.id, file_id: id, type: 'other'}))
            }));
          }

          await Promise.all(operations);

        } else if (offerContext.offer.delete_offer_files.length > 0) {
          await crud.data.bulk.delete({
            entity: 'offer_has_file', filter: {
              group: 'and', components: [
                {property: 'offer_id', operator: 'equals', value: offer.id},
                {property: 'type', operator: 'equals', value: 'other'}
              ]
            }
          });
        }

        setShowSuccess(true);
        offerContext.resetOffer();
        window.scrollTo({top: 0});

      })
      .catch(errors => setErrors(errors))
      .finally(() => setSubmitting(false));
  };

  const name = contactPerson => {
    let result  = ((contactPerson.first_name ?? '') + ' ' + (contactPerson.last_name ?? '')).trim();
    return result.length > 0 ? result : contactPerson.e_mail;
  };

  const [contactPersonsAvailable, setContactPersonsAvailable] = useState(false);
  const [headerImageAvailable, setHeaderImageAvailable] = useState(false);
  const [uspAvailable, setUspAvailable] = useState(false);
  const [faqAvailable, setFaqAvailable] = useState(false);
  const [testimonialsAvailable, setTestimonialsAvailable] = useState(false);
  const [pictureGalleryAvailable, setPictureGalleryAvailable] = useState(false);
  const [ctaAvailable, setCtaAvailable] = useState(false);
  const [translationAvailable, setTranslationAvailable] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      crud.data.bulk.read({entity: "contact_person", page_size: 0}),
      crud.data.bulk.read({entity: "header_image", page_size: 0}),
      crud.data.bulk.read({entity: "usp_group", page_size: 0}),
      crud.data.bulk.read({entity: "faq_group", page_size: 0}),
      crud.data.bulk.read({entity: "picture_gallery", page_size: 0}),
      crud.data.bulk.read({entity: "cta", page_size: 0}),
      crud.data.bulk.read({entity: "translation", page_size: 0}),
      crud.data.bulk.read({entity: "testimonial_group", page_size: 0}),
    ])
      .then(([contact_person, header_image, usp, faq, picture_gallery, cta, translation, testimonial]) => {
        setContactPersonsAvailable(contact_person.total > 0);
        setHeaderImageAvailable(header_image.total > 0);
        setUspAvailable(usp.total > 0);
        setFaqAvailable(faq.total > 0);
        setPictureGalleryAvailable(picture_gallery.total > 0);
        setCtaAvailable(cta.total > 0);
        setTranslationAvailable(translation.total > 0);
        setTestimonialsAvailable(testimonial.total > 0);
      })
      .finally(() => setIsLoading(false))
      .catch(errors => setErrors(errors));
  }, [crud.data.bulk]);

  const [liteRestrictions, setLiteRestrictions] = useState(true);
  useEffect(() => {
    crud.data.read({entity: "settings", id: 1})
      .then(settings => setLiteRestrictions(settings.subscription_plan === "lite"));
  }, [crud.data]);

  return (
    <>
      <Track>
        <TrackStation link="/angebot/neu/allgemeines">Allgemeine Angaben</TrackStation>
        <TrackStation link="/angebot/neu/einleitung">Einleitung</TrackStation>
        <TrackStation link="/angebot/neu/angebots-erlaeuterung">Angebots-Erläuterung</TrackStation>
        <TrackStation active>Elemente</TrackStation>
      </Track>
      <Formik
        initialValues={{
          contact_person_id: offerContext.offer.contact_person_id,
          team_members: offerContext.offer.team_members,
          header_image_id: offerContext.offer.header_image_id,
          usp_group_id: offerContext.offer.usp_group_id,
          faq_group_id: offerContext.offer.faq_group_id,
          picture_galleries: offerContext.offer.picture_galleries,
          logo_galleries: offerContext.offer.logo_galleries,
          cta_id: offerContext.offer.cta_id,
          documents: offerContext.offer.documents,
          translation_id: offerContext.offer.translation_id,
          testimonial_group_id: offerContext.offer.testimonial_group_id,
        }}
        onSubmit={onSubmit}
        validate={values => offerContext.setOffer({...offerContext.offer, ...values})}
      >
        {() => (
          !showSuccess &&
          <Form>
            <p className="text-center">Welche Elemente möchtest du noch hinzufügen?</p>
            <Accordion
              className="mt-1-75" title="Ansprechpartner deiner Firma" active={useContactPerson}
              onToggle={() => {
                setUseContactPerson(!useContactPerson);
                offerContext.setOffer({...offerContext.offer, use_contact_person: !useContactPerson});
              }}
            >
              {
                isLoading &&
                <div style={{height: "4.4rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                  <LoaderIcon/>
                </div>
              }
              {
                !contactPersonsAvailable &&
                !isLoading &&
                  <div style={{height: "4.4rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <p>
                      Du hast bisher keine Ansprechpartner angelegt.<br/>
                      Bitte gehe zu Angebotsdaten &gt; <NavLink to="/ansprechpartner">Ansprechpartner</NavLink>
                    </p>
                  </div>
              }
              {
                contactPersonsAvailable &&
                !isLoading &&
                <Row lgWidth="50%">
                  <SelectInput
                    label="Ansprechpartner" name="contact_person_id" menuPosition="fixed" entity="contact_person"
                    getOptionLabel={name}
                  />
                </Row>
              }
            </Accordion>

            {
              templateContext.template && templateContext.template.exclusiveFeatures.includes('team') &&
              <Accordion
                title="Teammitglieder" active={useTeamMembers}
                onToggle={() => {
                  setUseTeamMembers(!useTeamMembers);
                  offerContext.setOffer({...offerContext.offer, use_team_members: !useTeamMembers});
                }}
              >
                <SelectInput
                  label="Teammitglieder" name="team_members" menuPosition="fixed" entity="team_member"
                  getOptionLabel={item => `${item.first_name.trim()} ${item.last_name.trim()}`} isMulti isClearable
                />
              </Accordion>
            }

            <Accordion
              title="Titelbild" active={useHeaderImage}
              onToggle={() => {
                setUseHeaderImage(!useHeaderImage);
                offerContext.setOffer({...offerContext.offer, use_header_image: !useHeaderImage});
              }}
            >
              {
                isLoading &&
              <div style={{height: "4.4rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <LoaderIcon/>
              </div>
              }
              {
                !headerImageAvailable &&
                !isLoading &&
                <div style={{height: "4.4rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                  <p>
                    Du hast bisher keine Titelbilder hochgeladen.<br/>
                    Bitte gehe zu Angebotsdaten &gt; <NavLink to="/titelbilder">Titelbilder</NavLink>
                  </p>
                </div>
              }
              {
                headerImageAvailable &&
                !isLoading &&
                <Row lgWidth="50%">
                  <SelectInput
                    label="Titelbilder" name="header_image_id" menuPosition="fixed" entity="header_image"
                    getOptionLabel={item => (item.name).trim()}
                  />
                </Row>
              }
            </Accordion>

            <Accordion
              title="Bewertungen" active={useTestimonials}
              onToggle={() => {
                setUseTestimonials(!useTestimonials);
                offerContext.setOffer({...offerContext.offer, use_testimonials: !useTestimonials});
              }}
            >
              {
                isLoading &&
                <div style={{height: "5rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                  <LoaderIcon/>
                </div>
              }
              {
                !testimonialsAvailable &&
                !isLoading &&
                <div style={{height: "5rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                  <p>
                    Du hast bisher keine Bewertungen angelegt.<br/>
                    Bitte gehe zu Angebotsdaten &gt; <NavLink to="/bewertungsgruppen">Bewertungen</NavLink>
                  </p>
                </div>
              }
              {
                testimonialsAvailable &&
                !isLoading &&
                <>
                  <Row lgWidth="50%">
                    <SelectInput
                      label="Gruppe" name="testimonial_group_id" menuPosition="fixed" entity="testimonial_group"
                      getOptionLabel={item => (item.name).trim()}
                    />
                  </Row>
                </>
              }
            </Accordion>

            {
              !liteRestrictions &&
              <Accordion
                title="Unternehmensvorteile" active={useUsp}
                onToggle={() => {
                  setUseUsp(!useUsp);
                  offerContext.setOffer({...offerContext.offer, use_usp: !useUsp});
                }}
              >
                {
                  isLoading &&
                  <div style={{height: "5rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <LoaderIcon/>
                  </div>
                }
                {
                  !uspAvailable &&
                  !isLoading &&
                  <div style={{height: "5rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <p>
                      Du hast bisher keine Vorteile angelegt.<br/>
                      Bitte gehe zu Angebotsdaten &gt; <NavLink to="/usp-gruppen">Unternehmensvorteile</NavLink>
                    </p>
                  </div>
                }
                {
                  uspAvailable &&
                  !isLoading &&
                  <>
                    <Row lgWidth="50%">
                      <SelectInput
                        label="Gruppe" name="usp_group_id" menuPosition="fixed" entity="usp_group"
                        getOptionLabel={item => (item.name).trim()}
                      />
                    </Row>
                  </>
                }
              </Accordion>
            }
            {
              !liteRestrictions &&
              <Accordion
                title="FAQs" active={useFaq}
                onToggle={() => {
                  setUseFaq(!useFaq);
                  offerContext.setOffer({...offerContext.offer, use_faq: !useFaq});
                }}
              >
                {
                  isLoading &&
                  <div style={{height: "5rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <LoaderIcon/>
                  </div>
                }
                {
                  !faqAvailable &&
                  !isLoading &&
                  <div style={{height: "5rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <p>
                      Du hast bisher keine FAQ-Gruppe angelegt.<br/>
                      Bitte gehe zu Angebotsdaten &gt; <NavLink to="/faq-gruppen">FAQ</NavLink>
                    </p>
                  </div>
                }
                {
                  faqAvailable &&
                  !isLoading &&
                  <>
                    <Row lgWidth="50%">
                      <SelectInput
                        label="FAQ Paket" name="faq_group_id" menuPosition="fixed" entity="faq_group"
                        getOptionLabel={item => (item.name).trim()}
                      />
                    </Row>
                  </>
                }
              </Accordion>
            }
            <Accordion
              title="Galerien" active={usePictureGalleries}
              onToggle={() => {
                setUsePictureGalleries(!usePictureGalleries);
                offerContext.setOffer({...offerContext.offer, use_picture_galleries: !usePictureGalleries});
              }}
            >
              {
                isLoading &&
                <div style={{height: "5rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                  <LoaderIcon/>
                </div>
              }
              {
                !pictureGalleryAvailable &&
                !isLoading &&
                <div style={{height: "5rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                  <p>
                    Du hast bisher keine Galerie angelegt.<br/>
                    Bitte gehe zu Angebotsdaten &gt; <NavLink to="/galerien">Galerien</NavLink>
                  </p>
                </div>
              }
              {
                pictureGalleryAvailable &&
                !isLoading &&
                <>
                  <Row lgWidth="50%">
                    <SelectInput
                      label="Galerien" name="picture_galleries" menuPosition="fixed" entity="picture_gallery"
                      getOptionLabel={item => (item.headline).trim()} isMulti isClearable
                    />
                  </Row>
                </>
              }
            </Accordion>
            {
              templateContext.template && templateContext.template.exclusiveFeatures.includes('cta') &&
              <Accordion
                title="Call-To-Action" active={useCta}
                onToggle={() => {
                  setUseCta(!useCta);
                  offerContext.setOffer({...offerContext.offer, use_cta: !useCta});
                }}
              >
                {
                  isLoading &&
                  <div style={{height: "5rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <LoaderIcon/>
                  </div>
                }
                {
                  !ctaAvailable &&
                  !isLoading &&
                  <div style={{height: "5rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <p>
                      Du hast bisher keine Call-To-Actions angelegt.<br/>
                      Bitte gehe zu Angebotsdaten &gt; <NavLink to="/cta">Call-To-Actions</NavLink>
                    </p>
                  </div>
                }
                {
                  ctaAvailable &&
                  !isLoading &&
                  <>
                    <Row lgWidth="50%">
                      <SelectInput
                        label="Call-To-Action" name="cta_id" menuPosition="fixed" entity="cta"
                        getOptionLabel={item => (item.headline).trim()}
                      />
                    </Row>
                  </>
                }
              </Accordion>
            }
            {
              templateContext.template && templateContext.template.exclusiveFeatures.includes('logo_gallery') &&
              <Accordion
                title="Logo-Galerien" active={useLogoGalleries}
                onToggle={() => {
                  setUseLogoGalleries(!useLogoGalleries);
                  offerContext.setOffer({...offerContext.offer, use_logo_galleries: !useLogoGalleries});
                }}
              >
                <SelectInput
                  label="Logo-Galerien" name="logo_galleries" menuPosition="fixed" entity="logo_gallery"
                  getOptionLabel={item => (item.headline).trim()} isMulti isClearable
                />
              </Accordion>
            }
            <Accordion
              title="Weitere Dokumente" active={useDocuments}
              onToggle={() => {
                setUseDocuments(!useDocuments);
                offerContext.setOffer({...offerContext.offer, use_documents: !useDocuments});
              }}
            >
              <SelectInput
                label="Dokumente" name="documents" menuPosition="fixed" entity="document" isMulti isClearable
                getOptionLabel={item => item.name}
              />
            </Accordion>
            <Accordion
              title="Texte" active={useTranslation}
              onToggle={() => {
                setUseTranslation(!useTranslation);
              }}
            >
              {
                isLoading &&
                <div style={{height: "4.4rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                  <LoaderIcon/>
                </div>
              }
              {
                !translationAvailable &&
                !isLoading &&
                <div style={{height: "4.4rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                  <p>
                    Du hast bisher keine Textänderungen erstellt.<br/>
                    Bitte gehe zu Texte &gt; <NavLink to="/texte">Texte</NavLink>
                  </p>
                </div>
              }
              {
                translationAvailable &&
                !isLoading &&
                <Row lgWidth="50%">
                  <SelectInput
                    label="Textänderungen" name="translation_id" menuPosition="fixed" entity="translation"
                    getOptionLabel={item => (item.name).trim()}
                  />
                </Row>
              }
            </Accordion>
            <Accordion
              title="„Zum Angebot“ - Schaltfläche ausblenden?"
              active={parseInt(offerContext.offer.offer_button_disabled) === 1}
              onToggle={() => offerContext.offer.offer_button_disabled = 1 - offerContext.offer.offer_button_disabled}
            ></Accordion>
            <ButtonBar>
              <ButtonLink
                to="../angebots-erlaeuterung" text="Zurück" color="gray" icon={<ChevronLeftIcon/>} iconPosition="left"
              />
              <Button icon={<ChevronRightIcon/>} text="Speichern" type="submit"/>
            </ButtonBar>
          </Form>
        )}
      </Formik>
      <Modal
        title="Das Angebot wurde erfolgreich gespeichert" show={showSuccess}
        onDismiss={() => navigate('/angebote')}
      >
        <ButtonBar>
          <ButtonLink to="/angebote" text="Zurück zur Übersicht"/>
        </ButtonBar>
      </Modal>
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </>
  );
}
