import config from "../config.json";
import Title from "../components/Title";
import TitleHeading from "../components/TitleHeading";
import IconWithText from "../components/IconWithText";
import {ChevronRightIcon, LoaderIcon, TrashIcon} from "../components/Icons";
import {Form, Formik} from "formik";
import Row from "../components/Row";
import TextInput from "../components/TextInput";
import ButtonBar from "../components/ButtonBar";
import Button from "../components/Button";
import FormErrors from "../components/FormErrors";
import Modal from "../components/Modal";
import ErrorModal from "../components/ErrorModal";
import Stage from "../components/Stage";
import {useState} from "react";
import * as Yup from "yup";
import StageTitle from "../components/StageTitle";
import {useEffect} from "react";
import DropzoneInput from "../components/DropzoneInput";
import ImageInput from "../components/ImageInput";
import RowActionButton from "../components/RowActionButton";
import {useContext} from "react";
import {ThemeContext} from "../contexts/ThemeContext";
import useCRUD from "../hooks/useCRUD";
import useFiles from "../hooks/useFiles";
import {NavLink} from "react-router-dom";

export default function Unternehmensdaten() {

  const crud = useCRUD();
  const files = useFiles();
  const [item, setItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const {theme, setTheme} = useContext(ThemeContext);
  const [previousConversion, setPreviousConversion] = useState('');

  useEffect(() => {
    setLoading(true);
    Promise.all([
      crud.data.read({entity: 'company_figures', id: 1}),
      crud.data.read({entity: 'configuration', id: 1})
    ])
      .then(([companyFiguresResult, configurationResult]) => {
        setPreviousConversion(companyFiguresResult.previous_conversion || '');
        if (configurationResult.logo_id) {
          crud.data.read({entity: 'file', id: parseInt(configurationResult.logo_id)})
            .then(logo => {
              configurationResult.logo = {...logo};
              setItem({...configurationResult});
            })
            .catch(errors => setErrors(errors))
            .finally(() => setLoading(false));
        } else {
          setItem({...configurationResult});
          setLoading(false);
        }
      })
      .catch(errors => {
        setErrors(errors);
        setLoading(false);
      });
  }, [crud.data]);

  const validationSchema = Yup.object({
    email: Yup.string().required('Bitte geben Sie eine E-Mail-Adresse ein')
      .email('Bitte geben Sie eine gültige E-Mail-Adresse ein'),
    previous_conversion: Yup.number()
      .typeError('Bitte als ganze Zahl eintragen')
      .nullable()
      .integer('Bitte als ganze Zahl eintragen')
      .min(0, 'Diese Zahl muss mindestens 0 betragen')
      .max(100, 'Diese Zahl darf maximal 100 betragen')
  });

  const submit = (values, {setSubmitting}) => {
    let item = {...values};
    delete item.logo;
    delete item.previous_conversion;
    if (item.password) {
      delete item.password;
    }
    const newTheme = {...theme};
    const save = () => {
      Promise.all([
        crud.data.update({entity: 'configuration', id: 1, update: item}),
        crud.data.update({entity: 'company_figures', id: 1, update: {
          previous_conversion: parseInt(values.previous_conversion)
        }})
      ])
        .then(() => {
          setShowSuccess(true);
          newTheme.company = item.company;
          if (!newTheme.company) {
            delete newTheme.company;
          }
          if (!item.logo_id) {
            delete newTheme.logoUrl;
          }
          setTheme(newTheme);
        }
        )
        .catch(errors => setErrors(errors))
        .finally(() => setSubmitting(false));
    };
    if (values.logo) {
      files.upload(values.logo)
        .then(file => {
          item.logo_id = file.id;
          newTheme.logoUrl = file.url;
          save();
        })
        .catch(errors => {
          setErrors(errors);
          setSubmitting(false);
        });
    } else {
      save();
    }
  };

  return (
    <main>
      <Title>
        <TitleHeading>Unternehmensdaten</TitleHeading>
      </Title>
      <Stage>
        {loading && <IconWithText><LoaderIcon/> Laden…</IconWithText>}
        {!loading &&
          <Formik
            initialValues={{
              first_name: item.first_name || '',
              last_name: item.last_name || '',
              company: item.company || '',
              email: item.email || '',
              street: item.street || '',
              street_no: item.street_no || '',
              zip: item.zip || '',
              city: item.city || '',
              phone: item.phone || '',
              mobile: item.mobile || '',
              url: item.url || '',
              logo_id: item.logo_id,
              legal_info: item.legal_info || '',
              privacy_info: item.privacy_info || '',
              previous_conversion: previousConversion
            }}
            validationSchema={validationSchema}
            onSubmit={submit} onReset={() => setShowSuccess(false)}
          >
            {({isSubmitting, touched, errors, setFieldValue}) => (
              <Form noValidate>
                <StageTitle>Logo</StageTitle>
                {!item.logo && <DropzoneInput name="logo" text="Logo hochladen"/>}
                {
                  item.logo &&
                  <Row lgWidth="25%">
                    <ImageInput lable="Logo">
                      <RowActionButton title="Entfernen" color="red" type="button" onClick={() => {
                        const newItem = {...item};
                        delete newItem.logo;
                        newItem.logo_id = null;
                        setItem(newItem);
                        setFieldValue('logo_id', null);
                      }}><TrashIcon/></RowActionButton>
                      <img src={item.logo.url} alt="Logo"/>
                    </ImageInput>
                  </Row>
                }
                <hr/>
                <StageTitle>Persönliche Daten</StageTitle>
                <Row>
                  <TextInput name="first_name" label="Vorname" placeholder="Bsp. Sascha"/>
                  <TextInput name="last_name" label="Nachname" placeholder="Bsp. Weinrich"/>
                </Row>
                <Row>
                  <TextInput name="company" label="Deine Firma" placeholder="Bsp. vyn Marketing GmbH"/>
                  <TextInput type="email" name="email" label="Deine E-Mail-Adresse"/>
                </Row>
                <Row>
                  <TextInput name="street" label="Straße" placeholder="Bsp. Sandstraße"/>
                  <TextInput name="street_no" label="Hausnummer" placeholder="Bsp. 173"/>
                  <TextInput name="zip" label="PLZ" placeholder="Bsp. 57072"/>
                  <TextInput name="city" label="Ort" placeholder="Bsp. Siegen"/>
                </Row>
                <Row>
                  <TextInput name="phone" label="Telefon" placeholder="Bsp. 0271 38 79 79 90"/>
                  <TextInput name="mobile" label="Mobil" placeholder="Bsp. 0167 11122233"/>
                </Row>
                <Row>
                  <TextInput name="url" label="Webseite" placeholder="Bsp. https://sellestar.de/"/>
                </Row>
                <hr/>
                {
                  config.USE_AUTH &&
                  <>
                    <StageTitle>Passwort</StageTitle>
                    <Row lgWidth="50%">
                      <TextInput type="password" name="password" value="********"/>
                    </Row>
                    <div className="mt-1">
                      <NavLink className="default" to="/unternehmensdaten/neues-passwort">Passwort ändern</NavLink>
                    </div>
                    <hr/>
                  </>
                }
                <Row>
                  <TextInput
                    name="previous_conversion"
                    label={
                      `Von aktuell 100 ohne ${config.BRAND_DESIGN ? "Sellestar" : "diese Software"} `
                      + `verschickten Angeboten, wie viele werden ca. zum Auftrag?`
                    }
                  />
                </Row>
                <hr/>
                <StageTitle>Impressum und Datenschutz</StageTitle>
                <Row>
                  <TextInput
                    name="legal_info" label="Impressum" placeholder="Bsp. https://example.com/impressum"
                  />
                  <TextInput
                    name="privacy_info" label="Datenschutz" placeholder="Bsp. https://example.com/datenschutz"
                  />
                </Row>
                <hr/>
                <ButtonBar>
                  <Button
                    icon={isSubmitting ? <LoaderIcon/> : <ChevronRightIcon/>} text="Speichern" type="submit"
                    disabled={isSubmitting}
                  />
                </ButtonBar>
                <FormErrors touched={touched} errors={errors}/>
                <Modal
                  title="Ihre Daten wurden erfolgreich gespeichert"
                  show={showSuccess}
                  onDismiss={() => setShowSuccess(false)}
                >
                  <ButtonBar>
                    <Button type="button" text="Ok" onClick={() => setShowSuccess(false)}/>
                  </ButtonBar>
                </Modal>
              </Form>
            )}
          </Formik>
        }
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
      </Stage>
    </main>
  );

}
