import './App.scss';
import {useContext, useEffect} from "react";
import {Route, Routes, Navigate} from "react-router-dom";
import Dashboard from "./routes/dashboard";
import ListOffers from "./routes/offer/list";
import CreateOffer from "./routes/offer/create";
import FaqGroups from './routes/faq-groups';
import ContactPersons from './routes/contactPersons';
import VideosAndMemos from "./routes/videosAndMemos";
import Documents from "./routes/documents";
import Usp from "./routes/usp";
import Unternehmensdaten from "./routes/unternehmensdaten";
import Design from "./routes/design";
import CreateContactPerson from "./routes/createContactPerson";
import CreateFaqGroup from "./routes/createFaqGroup";
import FaqGroupDetails from "./routes/faqGroupDetails";
import DocumentDetails from "./routes/documentDetails";
import CreateDocument from "./routes/createDocument";
import EditFaqGroup from "./routes/editFaqGroup";
import CreateFaq from "./routes/createFaq";
import EditFaq from "./routes/editFaq";
import CreateUspGroup from "./routes/createUspGroup";
import UspGroup from "./routes/uspGroup";
import CreateUsp from "./routes/createUsp";
import EditUsp from "./routes/editUsp";
import EditUspGroup from "./routes/editUspGroup";
import EditContactPerson from "./routes/editContactPerson";
import Link from "./routes/offer/link";
import Login from "./routes/login";
import RequestPasswordRecovery from "./routes/requestPasswordRecovery";
import FirstOffer from "./routes/firstOffer";
import VideoRecording from "./routes/video/create";
import Screen from "./components/Screen";
import {AuthContextProvider} from "./contexts/AuthContext";
import RequireAuth from "./components/RequireAuth";
import DenyAuth from "./components/DenyAuth";
import config from "./config.json";
import ResetPassword from "./routes/resetPassword";
import ChangePassword from "./routes/changePassword";
import Statistics from "./routes/offer/statistics";
import VideoEdit from "./routes/video/videoEdit";
import VideoHochladen from "./routes/video/hochladen";
import Register from "./routes/register/register";
import MySubscription from "./routes/mySubscription";
import {RecordingContextProvider} from "./contexts/RecordingContext";
import {AuthContext} from "./contexts/AuthContext";
import LetterList from "./routes/letter/letterList";
import Create from "./routes/letter/create";
import Edit from "./routes/letter/edit";
import FaqStatistics from "./routes/offer/faqStatistics";
import EditDocument from "./routes/editDocument";
import HeaderImageList from "./routes/headerImage/headerImageList";
import HeaderImageCreate from "./routes/headerImage/headerImageCreate";
import HeaderImageDetails from "./routes/headerImage/headerImageDetails";
import HeaderImageEdit from "./routes/headerImage/headerImageEdit";
import Galerien from "./routes/galerien";
import GalerieNeu from "./routes/galerie/neu";
import GalerieDetails from "./routes/galerie/details";
import GalerieBearbeiten from "./routes/galerie/bearbeiten";
import GalerieEintragNeu from "./routes/galerie-eintrag/neu";
import GalerieEintragBearbeiten from "./routes/galerie-eintrag/bearbeiten";
import {TenantContextProvider} from "./contexts/TenantContext";
import {OfferContextProvider} from "./contexts/OfferContext";
import BewertungNeu from "./routes/bewertung/neu";
import BewertungBearbeiten from "./routes/bewertung/bearbeiten";
import Texte from "./routes/texte";
import TexteNeu from "./routes/texte/neu";
import TexteBearbeiten from "./routes/texte/bearbeiten";
import Team from "./routes/team";
import TeamMitgliedNeu from "./routes/team-mitglied/neu";
import TeamMitgliedBearbeiten from "./routes/team-mitglied/bearbeiten";
import LogoGalerien from "./routes/logoGalerien";
import LogoGalerieNeu from "./routes/logo-galerie/neu";
import LogoGalerieDetails from "./routes/logo-galerie/details";
import LogoGalerieBearbeiten from "./routes/logo-galerie/bearbeiten";
import LogoNeu from "./routes/logo-galerie-eintrag/neu";
import LogoBearbeiten from "./routes/logo-galerie-eintrag/bearbeiten";
import Cta from "./routes/cta";
import CtaNeu from "./routes/cta/neu";
import CtaBearbeiten from "./routes/cta/bearbeiten";
import OfferTemplate from "./routes/offerTemplate";
import CreateOfferTemplate from "./routes/angebots-vorlagen/neu";
import EditOfferTemplate from "./routes/angebots-vorlagen/bearbeiten";
import TemplateOffer from "./routes/offer/templateOffer";
import Bewertungsgruppen from "./routes/bewertungsgruppen";
import BewertungsgruppenDetails from "./routes/bewertungsgruppen/details";
import BewertungsgruppeBearbeiten from "./routes/bewertungsgruppen/bearbeiten";
import BewertungsgruppeNeu from "./routes/bewertungsgruppen/neu";
import {TemplateContextProvider} from "./contexts/TemplateContext";
import Benachrichtigungen from "./routes/benachrichtigungen";

export default function App() {

  const authContext = useContext(AuthContext);

  useEffect(() => {

    document.title = config.BRAND_DESIGN ? 'Sellestar' : '';

    const selectors = ['link[rel="apple-touch-icon"]', 'link[rel="manifest"]', 'link[rel="icon"]'];
    selectors.forEach(selector => {
      const el = document.querySelector(selector);
      if (el) {
        el.remove();
      }
    });

    if (config.BRAND_DESIGN) {
      const links = [
        { rel: 'apple-touch-icon', href: '/logo192.png' },
        { rel: 'manifest', href: '/manifest.json' },
        { rel: 'icon', href: '/favicon.ico' }
      ];
      links.forEach(({ rel, href }) => {
        const link = document.createElement('link');
        link.rel = rel;
        link.href = href;
        document.head.appendChild(link);
      });
    }

  }, []);

  return (
    <div className="App">
      <TenantContextProvider>
        <AuthContextProvider>
          <OfferContextProvider>
            <RecordingContextProvider>
              <TemplateContextProvider>

                <Routes>

                  <Route
                    path=""
                    element={
                      <Navigate
                        to={(!config.USE_AUTH || authContext.authToken) ? 'dashboard' : 'anmeldung'} replace={true}
                      />
                    }
                  />

                  <Route path="registrieren/*" element={<DenyAuth><Register/></DenyAuth>}/>

                  <Route path="anmeldung" element={<DenyAuth><Login/></DenyAuth>}/>
                  <Route path="anmeldung/:email" element={<DenyAuth><Login/></DenyAuth>}/>
                  <Route path="passwort-vergessen" element={<DenyAuth><RequestPasswordRecovery/></DenyAuth>}/>
                  <Route path="passwort-zuruecksetzen/:token" element={<DenyAuth><ResetPassword/></DenyAuth>}/>

                  <Route path="dashboard" element={<RequireAuth><Screen main={<Dashboard/>}/></RequireAuth>}/>

                  <Route path="angebote" element={<RequireAuth><Screen main={<ListOffers/>}/></RequireAuth>}/>
                  <Route path="angebot/neu/*" element={<RequireAuth><Screen main={<CreateOffer/>}/></RequireAuth>}/>
                  <Route path="angebot/erstes-angebot" element={<RequireAuth><FirstOffer/></RequireAuth>}/>
                  <Route path="angebot/schnell" element={<RequireAuth><Screen main={<TemplateOffer/>}/>
                  </RequireAuth>}/>
                  <Route path="angebot/:id/link" element={<RequireAuth><Screen main={<Link/>}/></RequireAuth>}/>
                  <Route
                    path="angebot/:id/statistik" element={<RequireAuth><Screen main={<Statistics/>}/></RequireAuth>}
                  />
                  <Route
                    path="angebot/:id/statistik/faq"
                    element={<RequireAuth><Screen main={<FaqStatistics/>}/></RequireAuth>}
                  />

                  <Route
                    path="ansprechpartner" element={<RequireAuth><Screen main={<ContactPersons/>}/></RequireAuth>}
                  />
                  <Route
                    path="ansprechpartner/neu"
                    element={<RequireAuth><Screen main={<CreateContactPerson/>}/></RequireAuth>}
                  />
                  <Route
                    path="ansprechpartner/:id/bearbeiten"
                    element={<RequireAuth><Screen main={<EditContactPerson/>}/></RequireAuth>}
                  />

                  <Route path="team" element={<RequireAuth><Screen main={<Team/>}/></RequireAuth>}/>
                  <Route
                    path="team-mitglied/neu"
                    element={<RequireAuth><Screen main={<TeamMitgliedNeu/>}/></RequireAuth>}
                  />
                  <Route
                    path="team-mitglied/:id/bearbeiten"
                    element={<RequireAuth><Screen main={<TeamMitgliedBearbeiten/>}/></RequireAuth>}
                  />

                  <Route path="video/aufnehmen/*" element={<RequireAuth><VideoRecording/></RequireAuth>}/>
                  <Route
                    path="videos-und-memos" element={<RequireAuth><Screen main={<VideosAndMemos/>}/></RequireAuth>}
                  />
                  <Route
                    path="video/:id/bearbeiten" element={<RequireAuth><Screen main={<VideoEdit/>}/></RequireAuth>}
                  />
                  <Route
                    path="video/hochladen" element={<RequireAuth><Screen main={<VideoHochladen/>}/></RequireAuth>}
                  />

                  <Route path="dokumente" element={<RequireAuth><Screen main={<Documents/>}/></RequireAuth>}/>
                  <Route path="dokumente/neu" element={<RequireAuth><Screen main={<CreateDocument/>}/></RequireAuth>}/>
                  <Route
                    path="dokumente/:id/details"
                    element={<RequireAuth><Screen main={<DocumentDetails/>}/></RequireAuth>}
                  />
                  <Route
                    path="dokumente/:id/bearbeiten"
                    element={<RequireAuth><Screen main={<EditDocument/>}/></RequireAuth>}
                  />

                  <Route path="anschreiben" element={<RequireAuth><Screen main={<LetterList/>}/></RequireAuth>}/>
                  <Route path="anschreiben/neu" element={<RequireAuth><Screen main={<Create/>}/></RequireAuth>}/>
                  <Route path="anschreiben/:id" element={<RequireAuth><Screen main={<Edit/>}/></RequireAuth>}/>

                  <Route path="titelbilder" element={<RequireAuth><Screen main={<HeaderImageList/>}/></RequireAuth>}/>
                  <Route
                    path="titelbilder/neu" element={<RequireAuth><Screen main={<HeaderImageCreate/>}/></RequireAuth>}
                  />
                  <Route
                    path="titelbilder/:id/details"
                    element={<RequireAuth><Screen main={<HeaderImageDetails/>}/></RequireAuth>}
                  />
                  <Route
                    path="titelbilder/:id/bearbeiten"
                    element={<RequireAuth><Screen main={<HeaderImageEdit/>}/></RequireAuth>}
                  />

                  <Route path="galerien" element={<RequireAuth><Screen main={<Galerien/>}/></RequireAuth>}/>
                  <Route
                    path="galerie/neu" element={<RequireAuth><Screen main={<GalerieNeu/>}/></RequireAuth>}
                  />
                  <Route
                    path="galerie/:id/details"
                    element={<RequireAuth><Screen main={<GalerieDetails/>}/></RequireAuth>}
                  />
                  <Route
                    path="galerie/:id/bearbeiten"
                    element={<RequireAuth><Screen main={<GalerieBearbeiten/>}/></RequireAuth>}
                  />

                  <Route
                    path="galerie-eintrag/:id/neu"
                    element={<RequireAuth><Screen main={<GalerieEintragNeu/>}/></RequireAuth>}
                  />
                  <Route
                    path="galerie-eintrag/:id/bearbeiten"
                    element={<RequireAuth><Screen main={<GalerieEintragBearbeiten/>}/></RequireAuth>}
                  />

                  <Route path="logo-galerien" element={<RequireAuth><Screen main={<LogoGalerien/>}/></RequireAuth>}/>
                  <Route
                    path="logo-galerie/neu" element={<RequireAuth><Screen main={<LogoGalerieNeu/>}/></RequireAuth>}
                  />
                  <Route
                    path="logo-galerie/:id/details"
                    element={<RequireAuth><Screen main={<LogoGalerieDetails/>}/></RequireAuth>}
                  />
                  <Route
                    path="logo-galerie/:id/bearbeiten"
                    element={<RequireAuth><Screen main={<LogoGalerieBearbeiten/>}/></RequireAuth>}
                  />

                  <Route
                    path="logo-galerie-eintrag/:id/neu"
                    element={<RequireAuth><Screen main={<LogoNeu/>}/></RequireAuth>}
                  />
                  <Route
                    path="logo-galerie-eintrag/:id/bearbeiten"
                    element={<RequireAuth><Screen main={<LogoBearbeiten/>}/></RequireAuth>}
                  />

                  <Route
                    path="bewertungsgruppen" element={<RequireAuth><Screen main={<Bewertungsgruppen/>}/></RequireAuth>}
                  />
                  <Route
                    path="bewertungsgruppen/:id/details"
                    element={<RequireAuth><Screen main={<BewertungsgruppenDetails/>}/></RequireAuth>}
                  />
                  <Route
                    path="bewertungsgruppen/:id/bearbeiten"
                    element={<RequireAuth><Screen main={<BewertungsgruppeBearbeiten/>}/></RequireAuth>}
                  />
                  <Route
                    path="bewertungsgruppen/neu"
                    element={<RequireAuth><Screen main={<BewertungsgruppeNeu/>}/></RequireAuth>}
                  />
                  <Route
                    path="bewertungsgruppen/:testimonial_group_id/neu"
                    element={<RequireAuth><Screen main={<BewertungNeu/>}/></RequireAuth>}
                  />
                  <Route
                    path="bewertungsgruppen/:testimonial_group_id/:testimonial_id/bearbeiten"
                    element={<RequireAuth><Screen main={<BewertungBearbeiten/>}/></RequireAuth>}
                  />

                  <Route path="faq-gruppen" element={<RequireAuth><Screen main={<FaqGroups/>}/></RequireAuth>}/>
                  <Route
                    path="faq-gruppen/neu" element={<RequireAuth><Screen main={<CreateFaqGroup/>}/></RequireAuth>}
                  />
                  <Route
                    path="faq-gruppen/:id/details"
                    element={<RequireAuth><Screen main={<FaqGroupDetails/>}/></RequireAuth>}
                  />
                  <Route
                    path="faq-gruppen/:id/bearbeiten"
                    element={<RequireAuth><Screen main={<EditFaqGroup/>}/></RequireAuth>}
                  />
                  <Route
                    path="faq-gruppen/:faq_group_id/:faq_id/bearbeiten"
                    element={<RequireAuth><Screen main={<EditFaq/>}/></RequireAuth>}
                  />
                  <Route path="faq-gruppen/:id/neu" element={<RequireAuth><Screen main={<CreateFaq/>}/></RequireAuth>}/>

                  <Route path="usp-gruppen/" element={<RequireAuth><Screen main={<Usp/>}/></RequireAuth>}/>
                  <Route
                    path="usp-gruppen/neu" element={<RequireAuth><Screen main={<CreateUspGroup/>}/></RequireAuth>}
                  />
                  <Route path="usp-gruppen/:id/" element={<RequireAuth><Screen main={<UspGroup/>}/></RequireAuth>}/>
                  <Route
                    path="usp-gruppen/:id/bearbeiten"
                    element={<RequireAuth><Screen main={<EditUspGroup/>}/></RequireAuth>}
                  />
                  <Route path="usp-gruppen/:id/neu" element={<RequireAuth><Screen main={<CreateUsp/>}/></RequireAuth>}/>
                  <Route
                    path="usp-gruppen/:usp_group_id/:usp_id/bearbeiten"
                    element={<RequireAuth><Screen main={<EditUsp/>}/></RequireAuth>}
                  />

                  <Route path="cta" element={<RequireAuth><Screen main={<Cta/>}/></RequireAuth>}/>
                  <Route path="cta/neu" element={<RequireAuth><Screen main={<CtaNeu/>}/></RequireAuth>}/>
                  <Route
                    path="cta/:id/bearbeiten" element={<RequireAuth><Screen main={<CtaBearbeiten/>}/></RequireAuth>}
                  />

                  <Route
                    path="angebots-vorlagen/" element={<RequireAuth><Screen main={<OfferTemplate/>}/></RequireAuth>}
                  />
                  <Route path="angebots-vorlagen/neu" element={<RequireAuth><Screen
                    main={<CreateOfferTemplate/>}/></RequireAuth>}/>
                  <Route path="angebots-vorlagen/:id/bearbeiten" element={<RequireAuth><Screen
                    main={<EditOfferTemplate/>}/></RequireAuth>}
                  />

                  <Route
                    path="gebuchtes-modell" element={<RequireAuth><Screen main={<MySubscription/>}/></RequireAuth>}
                  />

                  <Route
                    path="unternehmensdaten" element={<RequireAuth><Screen main={<Unternehmensdaten/>}/></RequireAuth>}
                  />
                  <Route
                    path="unternehmensdaten/neues-passwort"
                    element={<RequireAuth><Screen main={<ChangePassword/>}/></RequireAuth>}
                  />
                  <Route path="design" element={<RequireAuth><Screen main={<Design/>}/></RequireAuth>}/>

                  <Route path="texte" element={<RequireAuth><Screen main={<Texte/>}/></RequireAuth>}/>
                  <Route path="texte/neu" element={<RequireAuth><Screen main={<TexteNeu/>}/></RequireAuth>}/>
                  <Route
                    path="texte/:id/bearbeiten"
                    element={<RequireAuth><Screen main={<TexteBearbeiten/>}/></RequireAuth>}
                  />

                  <Route
                    path="benachrichtigungen"
                    element={<RequireAuth><Screen main={<Benachrichtigungen/>}/></RequireAuth>}
                  />

                  <Route
                    path="*"
                    element={
                      <Navigate
                        to={(!config.USE_AUTH || authContext.authToken) ? 'dashboard' : 'anmeldung'} replace={true}
                      />
                    }
                  />

                </Routes>

              </TemplateContextProvider>
            </RecordingContextProvider>
          </OfferContextProvider>
        </AuthContextProvider>
      </TenantContextProvider>
    </div>
  );
}
