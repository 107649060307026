import './Title.scss';
import config from "../config.json";

export default function Title(props) {
  return (
    <div className={'Title' + (config.BRAND_DESIGN ? '' : ' gray')}>
      {props.children}
    </div>
  );
}
