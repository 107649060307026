import './PublicModal.scss';
import config from "../config.json";

export default function PublicModal(props) {

  return (
    <div className={'PublicModal' + (config.BRAND_DESIGN ? '' : ' background-white')}>
      <div className="modal">
        {
          config.BRAND_DESIGN &&
          <div className="logo">
            <img src={process.env.PUBLIC_URL + '/logo.svg'} alt="Sellestar-Logo"/>
            <div className="brand">Sellestar</div>
            <div className="claim">One Click Success</div>
          </div>
        }
        <div className={'content' + (config.BRAND_DESIGN ? '' : ' background-gray')}>{props.children}</div>
      </div>
    </div>
  );

}
