import Title from '../../components/Title';
import Stage from '../../components/Stage';
import Badge from '../../components/Badge';
import Table from '../../components/Table';
import RowActionButton from '../../components/RowActionButton';
import {
  BarChartIcon, ChevronRightIcon, DuplicateIcon, EditIcon, EyeIcon, LinkIcon, LoaderIcon, PlusCircleIcon, TrashIcon
} from '../../components/Icons';
import TitleHeading from '../../components/TitleHeading';
import Column from "../../components/Column";
import ButtonBar from "../../components/ButtonBar";
import ButtonLink from "../../components/ButtonLink";
import RowActionLink from "../../components/RowActionLink";
import {useNavigate} from "react-router-dom";
import {useCallback, useContext, useEffect, useState} from "react";
import useCRUD from "../../hooks/useCRUD";
import useTemplates from "../../hooks/useTemplates";
import ErrorModal from "../../components/ErrorModal";
import IconWithText from "../../components/IconWithText";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import {OfferContext} from "../../contexts/OfferContext";
import Flex from "../../components/Flex";
import useBL from "../../hooks/useBL";
import Row from "../../components/Row";
import Select from "react-select";

export default function List() {

  const offerContext = useContext(OfferContext);
  const navigate = useNavigate();
  const crud = useCRUD();
  const bl = useBL();
  const templates = useTemplates();
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [duplicating, setDuplicating] = useState(false);
  const [templateCode, setTemplateCode] = useState();
  const [templateModal, setTemplateModal] = useState(false);

  const url = new URL(window.location.href);

  const date = date => (new Date(date)).toLocaleString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'});

  // Shortens a string to 20 characters and appends “…” if it is longer.
  // Removes a trailing space before the ellipsis.
  const cutWithEllipsis = input => {
    const inputString = (typeof input === 'string' ? input : String(input ?? '')).trim();
    return inputString.length > 20 ? `${inputString.slice(0, 19)}…` : inputString;
  };

  const existingOfferEdit = useCallback(() => {
    const offer = offerContext.offer;
    return Object.keys(offer).length > 0 && (
      !!offer.id || !!offer.number || !!offer.title || !!offer.client_company_name || offer.client_contact_name ||
      !!offer.opening || !!offer.letter || !!offer.introduction_video_id || !!offer.offer_video_id ||
      (offer.use_contact_person && !!offer.contact_person_id) || !!offer.net_price ||
      (offer.use_team_members && !!offer.team_members) ||
      (offer.use_header_image && !!offer.header_image_id > 0) ||
      (offer.use_header_image && !!offer.header_image_id) || (offer.use_cta && !!offer.cta_id) ||
      (offer.use_usp && !!offer.usp_group_id) || (offer.use_faq && !!offer.faq_group_id) ||
      (offer.use_picture_galleries && offer.picture_galleries.length > 0) ||
      (offer.use_logo_galleries && offer.logo_galleries.length > 0) ||
      (offer.use_testimonials && !!offer.testimonial_group_id) ||
      offer.new_offer_files.length > 0 || offer.delete_offer_files.length > 0 || offer.offer_files.length > 0 ||
      (offer.use_documents && offer.documents.length > 0) ||
      offer.offer_button_disabled === 1 || offer.use_customer_name === 1
    );
  }, [offerContext.offer]);

  useEffect(() => {
    bl.any_offers_created()
      .then(anyOffersCreated => {
        !anyOffersCreated && !existingOfferEdit() && navigate('/angebot/erstes-angebot', {replace: true});
      })
      .catch(errors => setErrors(errors));
  }, [bl, existingOfferEdit, navigate]);

  const [deleteRow, setDeleteRow] = useState(null);
  const doDelete = () => {
    setDeleting(true);
    crud.data.delete({entity: 'offer', id: deleteRow.id})
      .then(() => {
        setDeleteRow(null);
        setTableKey(tableKey + 1);
        if (deleteRow.id === offerContext.offer.id) {
          offerContext.resetOffer();
        }
      })
      .catch(errors => setErrors(errors))
      .finally(() => setDeleting(false));
  };

  const [duplicateRow, setDuplicateRow] = useState(null);
  const doDuplicate = () => {
    setDuplicating(true);
    crud.data.read({entity: 'offer', id: duplicateRow.id})
      .then(offerTemplate => {
        let code = '';
        const pool = '0123456789abcdef';
        for (let i = 0; i < 32; i++) {
          code += pool.charAt(Math.floor(Math.random() * pool.length));
        }
        const item = {...offerTemplate, code, status: 'open', title: `${offerTemplate.title} (Kopie)`};
        ['id', 'revision', 'created_at', 'updated_at', 'deleted_at'].forEach(property => delete item[property]);
        crud.data.create({entity: 'offer', item})
          .then(async newOffer => {
            const offerHasFileTemplates = await crud.data.bulk.read({
              entity: 'offer_has_file',
              filter: crud.filter.equals('offer_id', offerTemplate.id),
              page_size: 1000
            });
            if (offerHasFileTemplates.items.length > 0) {
              await crud.data.bulk.create({
                entity: 'offer_has_file',
                items: offerHasFileTemplates.items.map(offerHasFileTemplate => ({
                  offer_id: newOffer.id,
                  file_id: offerHasFileTemplate.file_id,
                  type: offerHasFileTemplate.type
                }))
              });
            }
            const offerHasPictureGalleryTemplates = await crud.data.bulk.read({
              entity: 'offer_has_picture_gallery',
              filter: crud.filter.equals('offer_id', offerTemplate.id),
              page_size: 1000
            });
            if (offerHasPictureGalleryTemplates.items.length > 0) {
              await crud.data.bulk.create({
                entity: 'offer_has_picture_gallery',
                items: offerHasPictureGalleryTemplates.items.map(offerHasPictureGalleryTemplate => ({
                  offer_id: newOffer.id,
                  picture_gallery_id: offerHasPictureGalleryTemplate.picture_gallery_id
                }))
              });
            }
          })
          .catch(errors => setErrors(errors))
          .finally(() => {
            setDuplicateRow(null);
            setTableKey(tableKey => tableKey + 1);
            setDuplicating(false);
          });
      })
      .catch(errors => setErrors(errors));
  };

  const [changeRowStatus, setChangeRowStatus] = useState(null);
  const setStatus = (row, status) => {
    if (row.status !== status) {
      crud.data.update({entity: 'offer', id: row.id, update: {status}})
        .then(() => setTableKey(tableKey + 1))
        .catch(errors => setErrors(errors));
    }
    setChangeRowStatus(null);
  };

  const [tableKey, setTableKey] = useState(1);

  useEffect(() => {
    crud.data.read({entity: 'design', id: 1})
      .then(design => {
        templates.forEach(template => {
          if (template.id === parseInt(design.template)) {
            setTemplateCode(template.code);
          }
        });
      })
      .catch(errors => setErrors(errors))
      .finally(() => setLoading(false));
  }, [crud.data, templates]);

  const sortSubsetInOrder = function(A, B) {
    let sortedSubset = [];
    for (let i = 0; i < A.length; i++) {
      if (B.includes(A[i])) {
        sortedSubset.push(A[i]);
      }
    }
    return sortedSubset;
  };

  const [loadingForEdit, setLoadForEdit] = useState();
  const edit = row => {
    setLoadForEdit(row.id);
    crud.data.read({entity: 'offer', id: row.id})
      .then(async offer => {

        let offerHasPictureGalleryIds = await crud.data.bulk.read({
          entity: 'offer_has_picture_gallery',
          page_size: 1000,
          filter: {property: 'offer_id', operator: 'equals', value: offer.id}
        }).then(bulkReadResult => bulkReadResult.items.map(item => item.picture_gallery_id));

        let pictureGalleries = [];
        if (offerHasPictureGalleryIds.length > 0) {
          pictureGalleries = await crud.data.bulk.read({
            entity: 'picture_gallery',
            page_size: 1000,
            filter: {
              group: 'or',
              components: offerHasPictureGalleryIds.map(
                offerHasPictureGalleryId => crud.filter.equals('id', offerHasPictureGalleryId)
              )
            }
          }).then(bulkReadResult => {
            let picture_gallery_ids = bulkReadResult.items.map(pictureGallery => pictureGallery.id);
            return sortSubsetInOrder(offerHasPictureGalleryIds, picture_gallery_ids);
          });
        } else if (offer.picture_gallery_id) {
          pictureGalleries.push(offer.picture_gallery_id);
        }

        let offerHasLogoGalleries = await crud.data.bulk.read({
          entity: 'offer_has_logo_gallery',
          page_size: 1000,
          filter: {property: 'offer_id', operator: 'equals', value: offer.id}
        }).then(bulkReadResult => bulkReadResult.items);

        let logoGalleries = [];
        if (offerHasLogoGalleries.length > 0) {
          logoGalleries = await crud.data.bulk.read({
            entity: 'logo_gallery',
            page_size: 1000,
            filter: {
              group: 'or',
              components: offerHasLogoGalleries.map(
                offerHasLogoGallery => ({
                  property: 'id',
                  operator: 'equals',
                  value: offerHasLogoGallery.logo_gallery_id
                })
              )
            }
          }).then(bulkReadResult => bulkReadResult.items.map(logoGallery => logoGallery.id));
        }

        let offerHasTeamMembers = await crud.data.bulk.read({
          entity: 'offer_has_team_member',
          page_size: 1000,
          filter: {property: 'offer_id', operator: 'equals', value: offer.id}
        }).then(bulkReadResult => bulkReadResult.items);

        let teamMembers = [];
        if (offerHasTeamMembers.length > 0) {
          teamMembers = await crud.data.bulk.read({
            entity: 'team_member',
            page_size: 1000,
            filter: {
              group: 'or',
              components: offerHasTeamMembers.map(
                offerHasTeamMember => ({
                  property: 'id', operator: 'equals', value: offerHasTeamMember.team_member_id
                })
              )
            }
          }).then(bulkReadResult => bulkReadResult.items.map(teamMember => teamMember.id));
        }

        let offerHasOtherFiles = await crud.data.bulk.read({
          entity: 'offer_has_file',
          page_size: 1000,
          filter: {group: 'and', components: [
            {property: 'offer_id', operator: 'equals', value: offer.id},
            {property: 'type', operator: 'equals', value: 'other'}
          ]}
        }).then(bulkReadResult => bulkReadResult.items);

        let documents = [];
        if (offerHasOtherFiles.length > 0) {
          documents = await crud.data.bulk.read({
            entity: 'document',
            page_size: 1000,
            filter: {
              group: 'or',
              components: offerHasOtherFiles.map(
                offerHasFile => ({property: 'file_id', operator: 'equals', value: offerHasFile.file_id})
              )
            }
          }).then(bulkReadResult => bulkReadResult.items.map(document => document.id));
        }

        let offerHasOfferFiles = await crud.data.bulk.read({
          entity: 'offer_has_file',
          page_size: 1000,
          filter: {group: 'and', components: [
            {property: 'offer_id', operator: 'equals', value: offer.id},
            {property: 'type', operator: 'equals', value: 'offer'}
          ]}
        }).then(bulkReadResult => bulkReadResult.items);

        const getOfferFiles = hasOfferFiles => {
          return new Promise(async resolve => {
            let offerFiles = [];
            for (const item of hasOfferFiles) {
              offerFiles.push(...await crud.expand([item], ['file']));
            }
            resolve(offerFiles);
          });
        };

        let offerFiles = await getOfferFiles(offerHasOfferFiles);

        let sourceTemplate = null;
        if (offer.source_template_id) {
          sourceTemplate = await crud.data.read({entity: 'offer', id: offer.source_template_id});
        }

        offerContext.setOffer({
          id: offer.id,
          source_template_id: offer.source_template_id,
          offerTemplate: sourceTemplate ? {value: sourceTemplate.id, label: sourceTemplate.title} : null,
          number: offer.number ?? '',
          title: offer.title ?? '',
          client_company_name: offer.client_company_name ?? '',
          client_contact_name: offer.client_contact_name ?? '',
          opening: offer.opening ?? '',
          letter: offer.letter ?? '',
          introduction_video_id: offer.introduction_video_id,
          offer_video_id: offer.offer_video_id,
          use_contact_person: !!offer.contact_person_id,
          contact_person_id: offer.contact_person_id,
          use_team_members: teamMembers.length > 0,
          team_members: teamMembers,
          use_header_image: !!offer.header_image_id,
          header_image_id: offer.header_image_id,
          net_price: offer.net_price ? (parseInt(offer.net_price) / 100).toFixed(2).replace('.', ',') : '',
          use_usp: !!offer.usp_group_id,
          usp_group_id: offer.usp_group_id,
          use_faq: !!offer.faq_group_id,
          faq_group_id: offer.faq_group_id,
          use_picture_galleries: pictureGalleries.length > 0,
          picture_galleries: pictureGalleries,
          use_cta: !!offer.cta_id,
          cta_id: offer.cta_id,
          use_logo_galleries: logoGalleries.length > 0,
          logo_galleries: logoGalleries,
          new_offer_files: [],
          offer_files: offerFiles,
          delete_offer_files: [],
          use_documents: documents.length > 0,
          use_testimonials: !!offer.testimonial_group_id,
          testimonial_group_id: offer.testimonial_group_id,
          translation_id: offer.translation_id,
          documents,
          offer_button_disabled: parseInt(offer.offer_button_disabled) === 1 ? 1 : 0,
          use_customer_name: parseInt(offer.use_customer_name) === 1 ? 1 : 0
        });
        navigate('/angebot/neu/allgemeines');
      })
      .catch(errors => setErrors(errors));
  };
  const [templateChooseModal, setTemplateChooseModal] = useState(null);
  const [offerTemplates, setOfferTemplates] = useState(null);
  const [offerTemplateSelected, setOfferTemplateSelected] = useState();
  const [selectOptions, setSelectOptions] = useState([]);
  const [offerTemplateMode, setOfferTemplateMode] = useState(null);
  useEffect(() => {
    crud.data.bulk.read({
      entity: "offer",
      page_size: 1000,
      filter: {
        property: 'is_template',
        operator: 'equals',
        value: '1'
      }
    })
      .then(offerTemplates => {
        let options = [];
        offerTemplates.items.forEach(item => {
          options.push({value: item.id, label: item.title});
        });
        setSelectOptions(options);
        if (offerTemplates.items.length > 0) {
          setOfferTemplates(offerTemplates.items);
        }
      })
      .catch(errors => setErrors([errors]));
  }, [crud.data]);

  const selectOfferTemplateMode = mode => {
    setTemplateModal(false);
    setTemplateChooseModal(true);
    setOfferTemplateMode(mode);
    offerContext.resetOffer();
  };
  const createOfferFromTemplate = value => {
    if (offerTemplateSelected) {
      offerContext.offer.offerTemplate = offerTemplateSelected;
      if (offerTemplateMode === 'complete') {
        navigate('/angebot/neu');
      } else {
        navigate('/angebot/schnell');
      }
    }
  };

  return (
    <main>
      <Title>
        <TitleHeading>Angebote</TitleHeading>
      </Title>
      <Stage>
        {
          existingOfferEdit() &&
          <ButtonBar align="left" className="mt-0 mb-1">
            {
              !offerContext.offer.id &&
              <ButtonLink
                to="/angebot/neu" text="Neues Angebot weiter bearbeiten" color="yellow" icon={<ChevronRightIcon/>}
              />
            }
            {
              offerContext.offer.id &&
              <ButtonLink
                to="/angebot/neu" text={`Angebot ${offerContext.offer.number} weiter bearbeiten`} color="yellow"
                icon={<ChevronRightIcon/>}
              />
            }
          </ButtonBar>
        }
        {loading && <IconWithText><LoaderIcon/> Laden…</IconWithText>}
        {
          !loading &&
          <Table
            name="offers" entity="offer" defaultOrderBy="created_at" defaultOrder="desc" key={tableKey}
            searchProperties={['number', 'client_company_name', 'title']} dropdownProperty="contact_person_id"
            dropdownFilterShowNull dropdownLabel="Ansprechpartner" excludeFilter={[{property: "is_template", value: 1}]}
          >
            <Column title="Angebotsnr." property="number"/>
            <Column title="Datum" property="created_at" transform={date}/>
            <Column title="Kunde" property="client_company_name"/>
            <Column title="Name" property="client_contact_name" transform={cutWithEllipsis}/>
            <Column title="Titel" property="title" transform={cutWithEllipsis}/>
            <Column title="Status" property="status" transform={() => null}>
              <RowActionButton color="green" title="Status" onClick={setChangeRowStatus}>
                {
                  row => {
                    switch (row.status) {
                    case "open":
                      return <Badge>Offen</Badge>;
                    case "won":
                      return <Badge color="green">Gewonnen</Badge>;
                    case "lost":
                      return <Badge color="red">Verloren</Badge>;
                    case "not-reached":
                      return <Badge color="yellow">Nicht erreicht</Badge>;
                    case "ring-back":
                      return <Badge color="orange">Meldet sich zurück</Badge>;
                    case "call-later":
                      return <Badge color="purple">Später anrufen</Badge>;
                    case "followed-up":
                      return <Badge color="light-yellow">Nachgefasst</Badge>;
                    default:
                    }
                  }
                }
              </RowActionButton>
            </Column>
            <Column type="actions">
              <RowActionLink
                to={row => `${url.origin}/${templateCode}/?code=${row.code}&noTrack`} color="green" title="Ansehen"
              >
                <EyeIcon/>
              </RowActionLink>
              <RowActionButton color="blue" title="Bearbeiten" onClick={edit}>
                {row => row.id === loadingForEdit ? <LoaderIcon/> : <EditIcon/>}
              </RowActionButton>
              <RowActionLink to={(row) => '/angebot/' + row.id + '/link'} color="yellow" title="Link abrufen">
                <LinkIcon/>
              </RowActionLink>
              <RowActionLink to={(row) => '/angebot/' + row.id + '/statistik'} color="purple" title="Statistiken">
                <BarChartIcon/>
              </RowActionLink>
              <RowActionButton color="turquoise" title="Angebot duplizieren" onClick={setDuplicateRow}>
                <DuplicateIcon/>
              </RowActionButton>
              <RowActionButton color="red" title="Löschen" onClick={setDeleteRow}><TrashIcon/></RowActionButton>
            </Column>
          </Table>
        }
        <ButtonBar>
          {
            existingOfferEdit() && !offerContext.offer.id &&
            <ButtonLink
              to="/angebot/neu" text="Neues Angebot weiter bearbeiten" color="yellow" icon={<ChevronRightIcon/>}
            />
          }
          {
            existingOfferEdit() && offerContext.offer.id &&
            <ButtonLink
              to="/angebot/neu" text={`Angebot ${offerContext.offer.number} weiter bearbeiten`} color="yellow"
              icon={<ChevronRightIcon/>}
            />
          }
          <Button text="Hinzufügen" icon={<PlusCircleIcon/>}
            onClick={() => setTemplateModal(true)}/>
        </ButtonBar>
        {
          changeRowStatus &&
          <Modal title="Status setzen" onDismiss={() => setChangeRowStatus(null)}>
            <p>Wählen Sie einen neuen Status für „{changeRowStatus.number}“ aus.</p>
            <Flex>
              <Badge status={changeRowStatus.status === 'lost' ? 'highlighted' : ''}
                clickable color="red" onClick={() => setStatus(changeRowStatus, 'lost')}
              >
                Verloren
              </Badge>
              <Badge status={changeRowStatus.status === 'open' ? 'highlighted' : ''}
                clickable onClick={() => setStatus(changeRowStatus, 'open')}
              >
                Offen
              </Badge>
              <Badge status={changeRowStatus.status === 'won' ? 'highlighted' : ''}
                clickable color="green" onClick={() => setStatus(changeRowStatus, 'won')}
              >
                Gewonnen
              </Badge>
              <Badge status={changeRowStatus.status === 'not-reached' ? 'highlighted' : ''}
                clickable color="yellow" onClick={() => setStatus(changeRowStatus, 'not-reached')}
              >
                Nicht erreicht
              </Badge>
              <Badge status={changeRowStatus.status === 'ring-back' ? 'highlighted' : ''}
                clickable color="orange" onClick={() => setStatus(changeRowStatus, 'ring-back')}
              >
                Meldet sich zurück
              </Badge>
              <Badge status={changeRowStatus.status === 'call-later' ? 'highlighted' : ''}
                clickable color="purple" onClick={() => setStatus(changeRowStatus, 'call-later')}
              >
                Später anrufen
              </Badge>
              <Badge status={changeRowStatus.status === 'followed-up' ? 'highlighted' : ''}
                clickable color="light-yellow" onClick={() => setStatus(changeRowStatus, 'followed-up')}
              >
                Nachgefasst
              </Badge>
            </Flex>
          </Modal>
        }
        {
          templateModal &&
          <Modal title="Wähle deinen Angebotstyp" onDismiss={() => setTemplateModal(false)}>
            <Row className="mt-1-75 mb-1">
              <Button text={`Schnellangebot mit Vorlage erstellen`} onClick={() => selectOfferTemplateMode('easy')}
                disabled={!offerTemplates} />
              <Button text={`Ausführliches Angebot mit Vorlage erstellen`}
                onClick={() => selectOfferTemplateMode('complete')} disabled={!offerTemplates}/>
              <ButtonLink
                to="/angebot/neu" text={`Ausführliches Angebot individuell erstellen`} onClick={offerContext.resetOffer}
              />
            </Row>
            {!offerTemplates &&
              <div>
                <p>Bitte erstellen Sie zuerst eine Angebotsvorlage um diese Optionen nutzen zu können</p>
                <ButtonLink to="/angebots-vorlagen" text={"Vorlage erstellen"}/>
              </div>}
          </Modal>
        }
        {
          templateChooseModal &&
          <Modal title="Wähle deine Vorlage" onDismiss={(e) => {}} dismissable={false}
          >
            <Select
              options={selectOptions}
              inputValue={''}
              onChange={e => setOfferTemplateSelected(e)}
              onInputChange={() => {}}
              onMenuClose={() => {}}
              onMenuOpen={() => {}}
              placeholder={<div>Auswählen...</div>}
              className="mt-1-75"
            />
            <ButtonBar>
              <Button color="grey" text="Zurück" onClick={() => {
                setTemplateModal(true);
                setTemplateChooseModal(false);
                setOfferTemplateSelected(false);
              }}/>
              <Button
                color="turquoise" text="Weiter" icon={<DuplicateIcon/>}
                onClick={() => createOfferFromTemplate()}
                disabled={!offerTemplateSelected}
              />
            </ButtonBar>

          </Modal>
        }
        {
          duplicateRow &&
          <Modal title="Duplizieren bestätigen" onDismiss={() => setDuplicateRow(null)}>
            <p>Möchten Sie das Angebot „{duplicateRow.number}“ wirklich duplizieren?</p>
            <ButtonBar>
              <Button color="grey" text="Abbrechen" disabled={duplicating} onClick={() => setDuplicateRow(null)}/>
              <Button
                color="turquoise" text="Duplizieren" icon={duplicating ? <LoaderIcon/> : <DuplicateIcon/>}
                disabled={duplicating} onClick={doDuplicate}
              />
            </ButtonBar>
          </Modal>
        }
        {
          deleteRow &&
          <Modal title="Löschen bestätigen" onDismiss={() => setDeleteRow(null)}>
            <p>Möchten Sie das Angebot „{deleteRow.number}" wirklich löschen?</p>
            <ButtonBar>
              <Button text="Behalten" disabled={deleting} onClick={() => setDeleteRow(null)}/>
              <Button
                color="red" text="Löschen" icon={deleting ? <LoaderIcon/> : <TrashIcon/>} disabled={deleting}
                onClick={doDelete}
              />
            </ButtonBar>
          </Modal>
        }
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
      </Stage>
    </main>
  );
}
