import React from "react";
import useClaims from "../hooks/useClaims";

export const OfferContext = React.createContext({offer: {}, setOffer: offer => {}, resetOffer: () => {}});

export function OfferContextProvider({children}) {

  const [offer, setOffer] = React.useState({});
  const claims = useClaims();

  const resetOffer = () => {
    setOffer({
      id: null,
      source_template_id: null,
      code: null,
      number: '',
      title: '',
      client_company_name: '',
      client_contact_name: '',
      opening: '',
      letter: '',
      introduction_video_id: null,
      offer_video_id: null,
      use_contact_person: false,
      contact_person_id: claims.user_id ?? null,
      team_members: [],
      use_team_members: false,
      net_price: '',
      status: 'open',
      use_header_image: false,
      header_image_id: null,
      use_usp: false,
      usp_group_id: null,
      use_faq: false,
      faq_group_id: null,
      picture_galleries: [],
      use_picture_galleries: false,
      cta_id: null,
      use_cta: false,
      logo_galleries: [],
      use_logo_galleries: false,
      use_testimonials: false,
      testimonial_group_id: null,
      new_offer_files: [],
      offer_files: [],
      delete_offer_files: [],
      use_documents: false,
      documents: [],
      translation_id: null,
      offer_button_disabled: 0,
      use_customer_name: 0
    });
  };

  return (
    <OfferContext.Provider value={{offer, setOffer, resetOffer}}>
      {children}
    </OfferContext.Provider>
  );

}
